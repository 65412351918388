import React from 'react';
import {Routes, Route, Navigate } from 'react-router-dom';
import Login from "../login/component/login";
import Register from "../register/component/register";
import Home from "../home/container/home";
import ServiceVariants from "../service/component/serviceVariant/serviceVariants";
import ServiceDetails from "../service/component/serviceOrder/serviceOrder";
import Dashboard from "../admin/dashboard/dashboard";
import UserList from "../admin/users/userList";
import ServiceForm from "../admin/service/service";
import OurWork from "../admin/ourWorks/ourWork";
import Messages from "../admin/contactUs/contactUs";
import OurOldWork from "../ourWorks/ourWorks";
import ContactUs from "../contactUs/contactUs";
import Services from "../service/component/services/services";
import CommonQuestions from "../CommonQuestions/commonQuestions";
import {useAuth} from "../../AuthContext";
import PersonalInfoContainer from "../personalInfo/personalInfoContainer";
import MyInfo from "../personalInfo/myInfo";
import PasswordChange from "../personalInfo/changePassword";
import OrderList from "../admin/orders/orderList";
import ReportList from "../admin/reports/ReportList";
import ServiceList from "../admin/service/serviceList";
import CommonQuestion from "../admin/commonQuestions/commonQuestions";
import ForgetPassword from "../../ResetPassword/forgetPassword";
import ResetPassword from "../../ResetPassword/resetPassword";

const Router = () => {
    const { user, loggedIn  } = useAuth();


    return (
        <>
            {Boolean(loggedIn) && user && user.role === "ADMIN" ? (
                <Routes>
                    <Route path="/admin/dashboard" element={<Dashboard />} />
                    <Route path="/admin/users" element={<UserList />} />
                    <Route path="/admin/service" element={<ServiceForm />} />
                    <Route path="/admin/service/list" element={<ServiceList />} />
                    <Route path="/admin/orders" element={<OrderList />} />
                    <Route path="/admin/reports" element={<ReportList />} />
                    <Route path="/admin/ourWorks" element={<OurWork />} />
                    <Route path="/admin/commonQuestions" element={<CommonQuestion />} />
                    <Route path="/admin/messages" element={<Messages />} />



                    <Route path="/" element={<Services />} />
                    <Route path="/OurWork" element={<OurOldWork />} />
                    <Route path="/commonQuestions" element={<CommonQuestions />} />
                    <Route path="/contactUs" element={<ContactUs />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />

                    <Route path="/profile" element={<PersonalInfoContainer />} />
                    <Route path="/profile/MyInfo" element={<PersonalInfoContainer><MyInfo /></PersonalInfoContainer>} />
                    <Route path="/profile/password" element={<PersonalInfoContainer><PasswordChange /></PersonalInfoContainer>} />
                    <Route path="/profile/orders" element={<PersonalInfoContainer />} />


                    <Route path="/services" element={<Services />} />
                    <Route path="/services/:service_id/variants" element={<ServiceVariants />} />
                    <Route path="/services/:service_id/variants/:service_variant_id/order" element={<ServiceDetails />} />
                    {/*<Route path="/*" element={<p>Not Found</p>} />*/}
                </Routes>
            ) : (
                <Routes>
                    <Route path="/" element={<Services />} />
                    <Route path="/OurWork" element={<OurOldWork />} />
                    <Route path="/commonQuestions" element={<CommonQuestions />} />
                    <Route path="/contactUs" element={<ContactUs />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/services/:service_id/variants" element={<ServiceVariants />} />
                    <Route path="/services/:service_id/variants/:service_variant_id/order" element={<ServiceDetails />} />
                    {/*<Route path="/*" element={<p>Not Found</p>} />*/}

                    <Route path="/profile" element={<PersonalInfoContainer />} />
                    <Route path="/profile/MyInfo" element={<PersonalInfoContainer><MyInfo /></PersonalInfoContainer>} />
                    <Route path="/profile/password" element={<PersonalInfoContainer><PasswordChange /></PersonalInfoContainer>} />
                    <Route path="/profile/orders" element={<PersonalInfoContainer />} />
                    <Route path={"/forgetPassword"} element={<ForgetPassword /> } />
                    <Route path="/resetPassword" element={<ResetPassword />} />
                </Routes>
            )}
        </>
    );
};

export default Router;