import React, {useState} from 'react';
import AdminLayout from "../layout/adminLayout";

const ServiceForm = () => {
    const [formData, setFormData] = useState({
        service_name: '',
        description: '',
        file: '',
        beneficiaries_type: [{beneficiaries_type: '', services_informations_required: []}],
        services_variants: [{service_variant_name: '', price: '', file: ''}]
    });
    const [error, setError] = useState('');

    const handleChange = (e, index, type, subType, subIndex) => {
        const {name, value} = e.target;
        if (subType !== undefined) {
            const updatedData = [...formData[type]];
            if (name === 'delete') {
                const updatedSubData = updatedData[index][subType].filter((_, i) => i !== subIndex);
                updatedData[index][subType] = updatedSubData;
            } else if (name === 'information_required_name') {
                updatedData[index][subType].push({[value]: ''});
            } else {
                updatedData[index][subType].push({[name]: value}); // Pushing a new object with the name and value
            }

            setFormData(prevState => ({
                ...prevState,
                [type]: updatedData
            }));
        } else {
            const updatedData = [...formData[type]];
            if (Array.isArray(updatedData[index])) {
                updatedData[index] = {...updatedData[index], [name]: value};
            } else {
                updatedData[index][name] = value;
            }
            setFormData(prevState => ({
                ...prevState,
                [type]: updatedData
            }));
        }
    };

    const handleServiceChange = (e, name) => {
        const {value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }


    const handleAddField = (type) => {
        setFormData(prevState => ({
            ...prevState,
            [type]: [...prevState[type], {beneficiaries_type: '', services_informations_required: []}]
        }));
    };

    const handleRemoveField = (index, type) => {
        const updatedData = [...formData[type]];
        updatedData.splice(index, 1);
        setFormData(prevState => ({
            ...prevState,
            [type]: updatedData
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Extracting form data from DOM elements
        const formData = {
            service_name: document.getElementById('service_name').value,
            description: document.getElementById('service_description').value,
            beneficiaries_type: [],
            services_variants: []
        };


        // Extracting main file as base64
        const fileInput = document.getElementById('service_file');
        const file = fileInput.files[0];
        if (file) {
            try {
                formData.file = await readFileAsBase64(file);

                // Extract file extension from file name
                const fileName = file.name;
                const fileExtension = fileName.split('.').pop().toLowerCase(); // Get the extension and convert to lowercase

                formData.extension = fileExtension;
            } catch (error) {
                console.error('Error reading file:', error);
            }
        }

        // Extracting beneficiaries_type data
        const beneficiaries = document.querySelectorAll('[name="beneficiaries_type"]');
        beneficiaries.forEach((beneficiary, index) => {
            const beneficiaryData = {
                beneficiaries_type: beneficiary.value,
                services_informations_required: []
            };
            const requiredInformations = document.querySelectorAll(`.information_required_name_${index}`);
            const requiredTypes = document.querySelectorAll(`.information_required_type_${index}`);
            requiredInformations.forEach((info, subIndex) => {
                const typeElement = requiredTypes[subIndex];
                if (info.value.trim() !== '') { // Check if the field is not empty
                    beneficiaryData.services_informations_required.push({
                        information_required_name: info.value,
                        information_required_type: typeElement ? typeElement.value : 'text' // Default to 'text' if typeElement is not found
                    });
                }
            });
            formData.beneficiaries_type.push(beneficiaryData);
        });

        // Extracting services_variants data
        const variants = document.querySelectorAll('[name="service_variant_name"]');
        const variantPromises = Array.from(variants).map(async (variant, index) => {
            const fileInput = document.querySelector(`.file_${index}`);
            const file = fileInput.files[0];
            if (file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                        formData.services_variants.push({
                            service_variant_name: variant.value,
                            base_price: parseFloat(document.querySelector(`.variant_base_price_${index}`).value),
                            price_for_companies: parseFloat(document.querySelector(`.variant_price_for_companies_${index}`).value),
                            price: parseFloat(document.querySelector(`.variant_price_${index}`).value),
                            file: reader.result,
                            extension: file.name.split('.').pop().toLowerCase()
                        });
                        resolve();
                    };
                });
            } else {
                formData.services_variants.push({
                    service_variant_name: variant.value,
                    price: parseFloat(document.querySelector(`.variant_price_${index}`).value),
                    file: null // or any default value for file if no file is selected
                });
                return Promise.resolve();
            }
        });

        // Wait for all file reading operations to complete
        await Promise.all(variantPromises);

        // JSON object built from form data
        console.log(formData);

        try {
            const messageElement = document.getElementById('message');
            messageElement.textContent = '';

            // Clear any existing classes
            messageElement.classList.remove('success', 'error');

            // Now you can make the API request
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/services`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            // Check if the request was successful
            if (response.ok) {
                const result = await response.json();
                // Handle the result as needed
                console.log(result);
                showMessageToUser('تم إضافة الخدمة بنجاح', 'success');
            } else {
                // Handle error response
                const errorData = await response.json();
                console.error('Error:', errorData);
                showMessageToUser('لم يتم إضافة الخدمة, هناك مشكلة ما حصلت', 'error');
            }
        } catch (error) {
            showMessageToUser('لم يتم إضافة الخدمة, هناك مشكلة ما حصلت', 'error');

        }
    };

    const showMessageToUser = (message, type) => {
        // Display the message to the user in the UI (e.g., using a toast, alert, or other UI component)
        const messageElement = document.getElementById('message');
        messageElement.textContent = message;

        // Clear any existing classes
        messageElement.classList.remove('success', 'error');

        // Apply appropriate class based on the type
        if (type === 'success') {
            messageElement.classList.add('success');
        } else if (type === 'error') {
            messageElement.classList.add('error');
        }
    };

// Function to read file as base64
    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
        });
    };

    return (
        <div>
            <AdminLayout>

                <h2 style={{fontSize: "16px"}} id={'message'}></h2>

                <h2>إضافة خدمة جديدة</h2>
                <form onSubmit={handleSubmit}>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6'}>
                            <label htmlFor="service_name">أسم الخدمة: </label>
                            <input type="text" id="service_name" name="service_name" value={formData.service_name}
                                   onChange={(e) => handleServiceChange(e, 'service_name')}/>

                        </div>

                        <div className={'col-12 col-md-6'}>

                            <label htmlFor="file">صورة واحدة للخدمة</label>
                            <input type="file" id="service_file" name="file" value={formData.file}
                                   onChange={(e) => handleServiceChange(e, 'file')}/>

                        </div>
                    </div>

                    <div className={'row'}>

                        <div className={'col-12 col-md-3'}>
                            <label htmlFor="description">الوصف :</label>
                            <textarea id="service_description" name="description" value={formData.description}
                                      onChange={(e) => handleServiceChange(e, 'description')}/>


                        </div>
                    </div>


                    {/* Beneficiaries Type Fields */}
                    {formData.beneficiaries_type.map((beneficiary, index) => (
                        <div key={index} className="row mb-2 mt-2">
                            {/* Beneficiary type */}
                            <div className="col-2">
                                <label>الجهة {index + 1} : المستفيدة من الخدمة</label>
                                <input type="text"
                                       className={`beneficiaries_type${index}`}
                                       name="beneficiaries_type"
                                       value={beneficiary.beneficiaries_type}
                                       placeholder="ادخل اسم الجهة المستفيدة هنا"
                                       onChange={(e) => handleChange(e, index, 'beneficiaries_type')}/>
                            </div>
                            {/* Required information fields for each beneficiary */}
                            <div className="col-6">
                                {beneficiary.services_informations_required.map((info, subIndex) => (
                                    <div key={subIndex} className="row mb-2">
                                        <div className="col-4">
                                            <input type="text"
                                                   className={`information_required_name_${index}`}
                                                   name="information_required_name"
                                                   value={info.information_required_name}
                                                   placeholder="ادخل اسم المعلومة المطلوبة"
                                                // onChange={(e) => handleChange(e, index, 'beneficiaries_type', 'services_informations_required', subIndex)}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <select name="information_required_type"
                                                    className={`information_required_type_${index}`}
                                                    value={info.information_required_type}
                                                // onChange={(e) => handleChange(e, index, 'beneficiaries_type', 'services_informations_required', subIndex)}
                                            >
                                                <option value="text">نص</option>
                                                <option value="date">تاريخ</option>
                                                <option value="number">رقم</option>
                                                <option value="file">ملف</option>
                                            </select>
                                        </div>
                                        <div className="col-4">
                                            <button className="btn btn-danger" type="button"
                                                    onClick={() => handleChange({target: {name: 'delete'}}, index, 'beneficiaries_type', 'services_informations_required', subIndex)}>
                                                حذف
                                            </button>
                                        </div>
                                    </div>
                                ))}

                                <button className="btn btn-primary" type="button" onClick={() => handleChange({
                                    target: {
                                        name: '',
                                        value: ''
                                    }
                                }, index, 'beneficiaries_type', 'services_informations_required')}>إضافة معلومة مطلوبة
                                </button>
                            </div>
                            {index > 0 &&
                                <div className="col-2">
                                    <button className={'btn btn-danger'} type="button"
                                            onClick={() => handleRemoveField(index, 'beneficiaries_type')}>
                                        حذف
                                    </button>
                                </div>
                            }
                        </div>
                    ))}


                    <div className={'row'}>
                        <div className={'col-7 m-4'}></div>
                        <div className={'col-2 float-end'}>
                            <button type="button" onClick={() => handleAddField('beneficiaries_type')}>إضافة جهة
                                المستفيدة
                            </button>
                        </div>
                    </div>


                    {/* Services Variants Fields */}
                    {formData.services_variants.map((variant, index) => (
                        <div key={index} className="row mb-2">
                            <div className="col-2">
                                <label>النوع {index + 1}</label>
                            </div>
                            <div className="col-6">
                                <input type="file" id="file" name="file"
                                       className={`file_${index}`}
                                />


                                <input className={`mt-2 variant_name_${index}`} type="text" name="service_variant_name"
                                       value={variant.service_variant_name}
                                       onChange={(e) => handleChange(e, index, 'services_variants')}
                                       placeholder={'ادخل اسم النوع هنا ....'}/>
                                <input type="number" name="base_price"
                                       className={`variant_base_price_${index}`}
                                       placeholder={'ادخل السعر التكلفة هنا ....'}
                                />
                                <input type="number" name="price_for_companies"
                                       className={`variant_price_for_companies_${index}`}
                                       placeholder={'ادخل السعر للجمعيات هنا ....'}
                                />
                                <input type="number" name="price" value={variant.price}
                                       className={`variant_price_${index}`}
                                       placeholder={'ادخل السعر هنا ....'}
                                       onChange={(e) => handleChange(e, index, 'services_variants')}/>


                            </div>
                            {index > 0 &&
                                <div className="col-2">
                                    <button className={'btn btn-danger'} type="button"
                                            onClick={() => handleRemoveField(index, 'services_variants')}>
                                        حذف
                                    </button>
                                </div>
                            }
                        </div>
                    ))}


                    <div className={'row'}>
                        <div className={'col-7 m-4'}></div>
                        <div className={'col-2 float-end'}>
                            <button className={'btn btn-primary mt-2'} type="button"
                                    onClick={() => handleAddField('services_variants')}>
                                إضافة نوع
                            </button>
                        </div>
                    </div>


                    <div className={'row'} dir={'ltr'}>
                        <div className={'col-2'}>
                            <button type="submit">إضافة الخدمة</button>
                        </div>
                    </div>
                </form>
                {error && <div className="error">{error}</div>}
            </AdminLayout>
        </div>
    );
};

export default ServiceForm;
