import React, { useState, useEffect } from 'react';
import CustomerLayout from '../layout/customerLayout';
import Loading from "../../Loading";

const OurOldWork = () => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedCommonQuestions, setUploadedCommonQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    // Function to fetch uploaded files
    const fetchUploadedFiles = async () => {
        try {
            let url = `${process.env.REACT_APP_API_URL}api/ourWorks?page=${currentPage}`;

            if (selectedService) {
                url += `&service_id=${selectedService}`;
            }

            const response = await fetch(url);
            const data = await response.json();
            setUploadedFiles(data.ourWorks ?? []);
            setUploadedCommonQuestions([]);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error('Error fetching uploaded files:', error);
        } finally {
            setIsLoading(false); // Set loading state to false regardless of success or failure
        }
    };
    // Function to fetch uploaded files
    const fetchUploadedFilesCommonQuestions = async () => {
        try {
            let url = `${process.env.REACT_APP_API_URL}api/commonQuestions?page=${currentPage}`;


            const response = await fetch(url);
            const data = await response.json();
            setUploadedCommonQuestions(data.common_questions ?? []);
            setUploadedFiles([]);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error('Error fetching uploaded files:', error);
        } finally {
            setIsLoading(false); // Set loading state to false regardless of success or failure
        }
    };

    const fetchServices = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/services`);
            const data = await response.json();
            setServices(data.result.services ?? []);
        } catch (error) {
            console.error('Error fetching services:', error);
        }
    };

    // Fetch uploaded files and services on component mount
    useEffect(() => {
        setIsLoading(true);
        if (selectedService === 'commonQuestions') {
            fetchUploadedFilesCommonQuestions();
        } else {
            // Set loading state to true when fetching data
            fetchUploadedFiles();
        }



        fetchServices();
    }, [currentPage, selectedService]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleServiceChange = (event) => {
        setSelectedService(event.target.value);
    };

    return (
        <div className={`transition-container ${isLoading ? '' : 'show'}`}>
            <div className="loading-container">
                {isLoading && <Loading />}
            </div>
            {!isLoading && (
                <div className="App">
                    <CustomerLayout height={70}>
                        <div className={'row'}>
                            <div className={'row'} dir={'rtl'}>
                                <h1 className={'text-center bold col-2'}>الأعمال السابقة</h1>
                                {/* Select dropdown list for services */}
                                <div className={'col-2'}>
                                    <select className={'form-select col-12'} value={selectedService} onChange={handleServiceChange}>
                                        <option value="" style={{ textAlign: 'right' }}>الجميع</option>
                                        {services.map(service => (
                                            <option key={service.service_id} value={service.service_id}>{service.service_name}</option>
                                        ))}

                                        <option value="commonQuestions" style={{ textAlign: 'right' }}>الأسئلة الشائعة</option>

                                    </select>
                                </div>
                            </div>
                            <div className={'row mt-5'}>
                                {uploadedFiles.map((file, index) => {
                                    const videoPath = `${process.env.REACT_APP_API_URL}${file.file.path.replace(/\\/g, '/')}`;
                                    return (
                                        <div className={'col-6 col-sm-4 '} key={index}>
                                            <h3>{file.description}</h3>
                                            <video key={file.ourWork_id} controls width="100%" height="300">
                                                <source src={videoPath} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={'row mt-5'}>
                                {uploadedCommonQuestions.map((file, index) => {
                                    const filePath = `${process.env.REACT_APP_API_URL}${file.file?.path.replace(/\\/g, '/')}`;
                                    return (
                                        <div className="col-12  m-5 text-center" key={index}>
                                            <h4>{file.question}</h4>
                                            {file.answer ? (
                                                <p>{file.answer}</p>
                                            ) : (
                                                <audio controls>
                                                    <source src={filePath} type="audio/mp3" />
                                                    Your browser does not support the audio element.
                                                </audio>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={'d-flex justify-content-center'}>
                                <ul className="pagination mt-3 cursor-pointer">
                                    {/* First Page Button */}
                                    <li>
                                        <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>&laquo;</button>
                                    </li>
                                    {/* Previous Page Button */}
                                    <li>
                                        <button onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                                                disabled={currentPage === 1}>&lt;</button>
                                    </li>
                                    {/* Display a range of pages around the current page */}
                                    {Array.from({ length: totalPages > 5 ? 5 : totalPages }).map((_, index) => {
                                        const page = index + 1;
                                        const pageToShow = currentPage <= 3 ? page : currentPage + index - 2;
                                        return pageToShow <= totalPages ? (
                                            <li key={index}>
                                                <button
                                                    onClick={() => handlePageChange(pageToShow)}
                                                    className={currentPage === pageToShow ? "active" : ""}
                                                >
                                                    {pageToShow}
                                                </button>
                                            </li>
                                        ) : null;
                                    })}
                                    {/* Next Page Button */}
                                    <li>
                                        <button onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                                                disabled={currentPage === totalPages}>&gt;</button>
                                    </li>
                                    {/* Last Page Button */}
                                    <li>
                                        <button onClick={() => handlePageChange(totalPages)}
                                                disabled={currentPage === totalPages}>&raquo;</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </CustomerLayout>
                </div>
            )}
        </div>
    );
};

export default OurOldWork;
