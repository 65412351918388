import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';

const Cards = (props) => {
    const navigate = useNavigate();

    const imageBaseUrl = process.env.REACT_APP_API_URL; // Update with your backend URL
    const imagePath = imageBaseUrl + props.imgPath.replace('\\', '/');

    const handleNavigate = () => {
        // Example navigation to /service/123/details
        navigate(props.navigateAction);
    };

    return (
        <Card style={{ width: '100%', margin: "10px" }}>
            <div style={{ position: 'relative', width: '100%', paddingBottom: '75%', overflow: 'hidden' }}>
                <Card.Img
                    variant="top"
                    src={imagePath}
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', border: '1px solid #ccc' }}
                />
            </div>
            <Card.Body>
                <Card.Title style={{ fontWeight: "bold" }}>{props.service_name}</Card.Title>
                <Card.Text style={{ fontWeight: "bold" }} dir={'rtl'}>
                        {props.description}
                </Card.Text>
                <Button variant="primary" onClick={handleNavigate}>{props.actionText}</Button>
            </Card.Body>
        </Card>
    );
}

export default Cards;
