import React, { useState } from 'react';
import './admin.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {NavLink} from "react-router-dom";
import {useAuth} from "../../../AuthContext";


const AdminLayout = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { user, loggedIn, logout  } = useAuth();

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div dir={'rtl'}>
            <header className={'header fixed-top d-flex align-items-center p-4'}>
                <div className={'d-flex align-items-center justify-content-between'}>
                    <a className={'logo d-flex align-items-center text-decoration-none'}>
                        <img src={'/logo.png'} alt={''}/>
                    </a>

                    <i className={'bi bi-list '} onClick={toggle}></i>

                    <NavLink to={'/'} className={'btn btn-success mx-2'}>الصفحة الرئيسية للزبائن</NavLink>
                </div>
                <nav className={'navbar navbar-expand me-auto'}>
                    <ul className={'navbar-nav d-flex align-items-center'}>
                        <li className="nav-item dropdown pe-3">
                            <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                <img src="/logo.png" alt="الملف الشخصي" className="profile-img rounded-circle"/>
                                <span className="d-none d-md-block dropdown-toggle ps-2">{(loggedIn && user) ? user.display_name : ''}</span>
                            </a>

                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                <li className={'text-center'}>
                                    <button className={'mx-4'} onClick={logout}>تسجيل خروج</button>
                                </li>

                            </ul>
                        </li>  {/*profile*/}
                    </ul>
                </nav>
            </header>

            <aside  id="sidebar" className={'sidebar '} style={{ right: isOpen ? "-300px" : "0px" }} data-bs-parent="sidebar-nav">
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item ">
                        <NavLink to={'/admin/dashboard'} className="nav-link ">
                            <i className="bi bi-grid"></i>
                            <span>لوحة القيادة</span>
                        </NavLink>
                    </li>
                    <li className="nav-item ">
                        <NavLink to={'/admin/users'} className="nav-link ">
                            <i className="bi bi-person"></i>
                            <span>المستخدمين</span>
                        </NavLink>
                    </li>
                    {/*<li className="nav-item ">*/}
                    {/*    <NavLink to={'/admin/service'}  className="nav-link ">*/}
                    {/*        <i className="bi bi-menu-button-wide"></i>*/}
                    {/*        <span>الخدمات</span>*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}

                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-menu-button-wide"></i>
                            <span>الخدمات</span>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><NavLink to={'/admin/service'} className="dropdown-item">إضافة خدمة جديدة</NavLink></li>
                            <li><NavLink to={'/admin/service/list'} className="dropdown-item">المعاملات على الخدمات</NavLink></li>
                        </ul>
                    </li>


                    <li className="nav-item ">
                        <NavLink to={'/admin/orders'} className="nav-link ">
                            <i className="bi bi-journal-text"></i>
                            <span>الطلبات</span>
                        </NavLink>
                    </li>
                    <li className="nav-item ">
                        <NavLink to={'/admin/ourWorks'} className="nav-link ">
                            <i className="bi bi-award"></i>
                            <span>الأعمال السابقة</span>
                        </NavLink>
                    </li>
                    <li className="nav-item ">
                        <NavLink to={'/admin/commonQuestions'} className="nav-link ">
                            <i className="bi bi-award"></i>
                            <span>الأسئلة الشائعة</span>
                        </NavLink>
                    </li>
                    <li className="nav-item ">
                        <NavLink to={'/admin/messages'} className="nav-link ">
                            <i className="bi bi-envelope"></i>
                            <span>طلبات التواصل</span>
                        </NavLink>
                    </li>
                    <li className="nav-item ">
                        <NavLink to={'/admin/reports'} className="nav-link ">
                            <i className="bi bi-layout-text-window-reverse"></i>
                            <span>التقارير</span>
                        </NavLink>
                    </li>
                </ul>
            </aside>

            <main id="main" className="main" style={{ marginRight: isOpen ? "20px" : "320px", transition: "all 0.3s", marginTop: "70px", padding: "20px" }}>
                {children}
            </main>
        </div>
    );
};


export default AdminLayout;
