import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from './AuthContext';

const SimpleRegister = ({ onSuccess, onClose }) => {
    const { register } = useAuth();
    const [formData, setFormData] = useState({
        display_name: "",
        email: "",
        password: "",
        phone_number: "",
    });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const success = await register(formData);
        if (success === true) {
            onSuccess();
        } else {
            setErrors(success);
            // setErrors({ registration: "Registration failed" });
        }
    };

    const handleClose = () => {
        setFormData({
            display_name: "",
            email: "",
            password: "",
            phone_number: "",
        });
        setErrors({});
        onClose();
    };

    return (
        <div>
            <h2>تسجيل حساب جديد</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label> الاسم</label>
                    <input type="text" className="form-control" placeholder="ادخل الاسم هنا" name="display_name" value={formData.display_name} onChange={handleChange} />
                    {errors.display_name && <span className="text-danger">{errors.display_name}</span>}
                </div>
                <div className="form-group">
                    <label> رقم الهاتف</label>
                    <input type="text" className="form-control" placeholder="ادخل رقم الهاتف هنا" name="phone_number" value={formData.phone_number} onChange={handleChange} />
                    {errors.phone_number && <span className="text-danger">{errors.phone_number}</span>}
                </div>
                <div className="form-group">
                    <label> البريد الالكتروني</label>
                    <input type="email" className="form-control" placeholder="ادخل البريد الالكتروني هنا" name="email" value={formData.email} onChange={handleChange} />
                    {errors.email && <span className="text-danger">{errors.email}</span>}
                </div>
                <div className="form-group">
                    <label>الرقم السري</label>
                    <input type="password" className="form-control" placeholder="**************" name="password" value={formData.password} onChange={handleChange} />
                    {errors.password && <span className="text-danger">{errors.password}</span>}
                </div>

                <button type="submit" className="btn btn-primary mx-2">تسجيل حساب جديدد</button>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>إلغاء</button>
            </form>
        </div>
    );
}

export default SimpleRegister;
