import React, {useState, useEffect} from 'react';
import './users.css';
import AdminLayout from "../layout/adminLayout";

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [usersPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');
    const [validationDeleteErrors, setValidationDeleteErrors] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const [newUser, setNewUser] = useState({
        display_name: '',
        phone_number: '',
        email: '',
        password: '',
        role: '',
        logo: null
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);

    const [updatedUser, setUpdatedUser] = useState({
        display_name: '',
        phone_number: '',
        email: '',
        password: '',
        role: ''
    });
    const [updateSuccessMessage, setUpdateSuccessMessage] = useState('');
    const [updateValidationErrors, setUpdateValidationErrors] = useState([]);

    // Fetching users data (mock data for demonstration)
    useEffect(() => {
        fetchUsers();
    }, [currentPage, searchTerm]);

    const fetchUsers = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/users?page=${currentPage}&inquiry=${searchTerm}`);
        const data = await response.json();

        setUsers(data.users);
        setTotalPages(data.totalPages);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


// Function to open Add New User modal
    const openAddModal = () => {
        setShowAddModal(true);
    };

    // Function to close Add New User modal
    const closeAddModal = () => {
        setShowAddModal(false);
    };

    // Function to handle form input changes
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setNewUser(prevUser => ({
            ...prevUser,
            [name]: value
        }));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            // Update newUser with the base64 string of the uploaded file
            const extension = file.name.split('.').pop();

            setNewUser({...newUser, logo: {data: reader.result, extension: extension}});
        };

        if (file) {
            // Read the file as a data URL (base64)
            reader.readAsDataURL(file);
        }
    }

    // Function to open Update User modal
    const openUpdateModal = (userId) => {
        const user = users.find(user => user.user_id === userId);
        setSelectedUser(user);
        setUpdatedUser({
            display_name: user.display_name,
            phone_number: user.phone_number,
            email: user.email,
            password: user.password,
            role: user.role
        });
        setShowUpdateModal(true);
    };

    // Function to handle form input changes for updating user
    const handleUpdateInputChange = (e) => {
        const {name, value} = e.target;
        setUpdatedUser(prevUser => ({
            ...prevUser,
            [name]: value
        }));
    };

    // Function to handle form submission for updating user
    const handleSubmitUpdate = (e) => {
        e.preventDefault();
        // Implement your logic to update user here
        console.log('Updated user:', updatedUser);
        // After updating user, you may want to close the modal
        setShowUpdateModal(false);
    };


    // Function to close Update User modal
    const closeUpdateModal = () => {
        setShowUpdateModal(false);
        setSelectedUser(null);
    };

    // Function to open Delete User modal
    const openDeleteModal = (userId) => {
        const user = users.find(user => user.user_id === userId);
        setSelectedUser(user);
        setShowDeleteModal(true);
    };

    // Function to close Delete User modal
    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedUser(null);
    };

    // Function to handle delete user action
    const deleteUser = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/${selectedUser.user_id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                // Fetch users again after deleting to update the list
                fetchUsers();
                setDeleteSuccessMessage('تم حذف المستخدم بنجاح');
                setTimeout(() => {
                    closeDeleteModal();
                }, 1000);
            } else {
                setDeleteSuccessMessage('');
                setValidationDeleteErrors('حدثت مشكلة في حذف المستخدم');
                console.error('Failed to delete user');
            }
        } catch (error) {
            setDeleteSuccessMessage('');
            setValidationDeleteErrors('حدثت مشكلة في حذف المستخدم');
            console.error('Error deleting user:', error);
        }
    };

    const addUser = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newUser),
            });
            if (response.ok) {
                // Fetch users again after adding to update the list
                fetchUsers();
                setSuccessMessage('تم اضافة المستخدم بنجاح');
                setValidationErrors([]);

                setTimeout(() => {
                    setShowAddModal(false);
                    setSuccessMessage('');
                    setNewUser({
                        display_name: '',
                        phone_number: '',
                        email: '',
                        password: '',
                        role: '',
                        logo: null
                    });
                }, 1000);
            } else {
                const errorData = await response.json();
                const errors = errorData.errors;

                // write code here
                setValidationErrors(errors);
                setSuccessMessage('');

            }
        } catch (error) {
            console.error('Error adding user:', error);
            setSuccessMessage('');

        }
    };

    const updateUser = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/${selectedUser.user_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedUser),
            });
            if (response.ok) {
                // Fetch users again after updating to update the list
                fetchUsers();

                setUpdateSuccessMessage('تم تحديث المستخدم بنجاح');
                setUpdateValidationErrors([]);
                setTimeout(() => {
                    closeUpdateModal();
                    setUpdateSuccessMessage('');

                }, 1000);
            } else {
                const errorData = await response.json();
                const errors = errorData.errors;

                // Set validation errors
                setUpdateValidationErrors(errors);
                setUpdateSuccessMessage('');
            }
        } catch (error) {
            console.error('Error updating user:', error);
            setUpdateSuccessMessage('');
        }
    };

    function RoleComponent({role}) {
        let roleName = '';
        let badgeClass = '';
        switch (role) {
            case 'USER':
                roleName = 'مستخدم';
                badgeClass = 'badge bg-success ';
                break;
            case 'COMPANY':
                roleName = 'جمعية';
                badgeClass = 'badge bg-info ';
                break;
            case 'ADMIN':
                roleName = 'مدير موقع';
                badgeClass = 'badge bg-warning ';
                break;
            default:
                roleName = 'غير معروف';
                badgeClass = 'badge bg-danger ';
        }

        return <span className={badgeClass}>{roleName}</span>;
    }


    return (
        <div className="user-list-container">
            <AdminLayout>
                <h1>قائمة المستخدمين</h1>
                {showAddModal && (
                    <div className="modal">
                        {/* Modal content for adding new user */}
                        <div className="modal-content">
                            <span className="close" onClick={closeAddModal}>&times;</span>
                            <h3>إضافة مستخدم جديد</h3>
                            <div>
                                <label htmlFor="display_name">الاسم:</label>
                                <input
                                    type="text"
                                    id="display_name"
                                    name="display_name"
                                    value={newUser.display_name}
                                    onChange={handleInputChange}
                                />
                                {validationErrors && validationErrors.map(error => (
                                    error.path === 'display_name' && <div className="error">{error.msg}</div>
                                ))}
                            </div>
                            <div>
                                <label htmlFor="phone_number">رقم الهاتف:</label>
                                <input
                                    type="text"
                                    id="phone_number"
                                    name="phone_number"
                                    value={newUser.phone_number}
                                    onChange={handleInputChange}
                                />
                                {validationErrors && validationErrors.map(error => (
                                    error.path === 'phone_number' && <div className="error">{error.msg}</div>
                                ))}
                            </div>


                            <div className={'mt-1'}>
                                <label htmlFor="role">نوع المستخدم:</label>
                                <select
                                    className={'form-select'}
                                    id="role"
                                    name="role"
                                    value={newUser.role}
                                    onChange={handleInputChange}
                                >
                                    <option value="">اختر نوع المستخدم</option>
                                    <option value="ADMIN">مدير</option>
                                    <option value="USER">مستخدم</option>
                                    <option value="COMPANY">جمعية</option>
                                </select>
                                {validationErrors && validationErrors.map(error => (
                                    error.path === 'role' && <div className="error">{error.msg}</div>
                                ))}
                            </div>

                            {newUser.role === 'COMPANY' && (
                                <div className={'mt-1'}>
                                    <label htmlFor="logo">لوجو الجمعية:</label>
                                    <input
                                        type="file"
                                        id="logo"
                                        name="logo"
                                        onChange={handleFileChange}
                                    />
                                    {validationErrors && validationErrors.map(error => (
                                        error.path === 'logo' && <div className="error">{error.msg}</div>
                                    ))}
                                </div>
                            )
                            }

                            <div className={'mt-1'}>
                                <label htmlFor="email">البريد الإلكتروني:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={newUser.email}
                                    onChange={handleInputChange}
                                />
                                {validationErrors && validationErrors.map(error => (
                                    error.path === 'email' && <div className="error">{error.msg}</div>
                                ))}
                            </div>
                            <div>
                                <label htmlFor="password">كلمة المرور:</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={newUser.password}
                                    onChange={handleInputChange}
                                />
                                {validationErrors && validationErrors.map(error => (
                                    error.path === 'password' && <div className="error">{error.msg}</div>
                                ))}
                            </div>

                            <button className={'mt-2'} type="button" onClick={addUser}>إضافة</button>

                            {successMessage && <div className="success">{successMessage}</div>}
                        </div>
                    </div>
                )}


                {/* Update User Modal */}
                {showUpdateModal && selectedUser && (
                    <div className="modal">
                        {/* Modal content for updating user */}
                        <div className="modal-content">
                            <span className="close" onClick={closeUpdateModal}>&times;</span>
                            <h3>تحديث المستخدم</h3>
                            {/* Update user form */}
                            <form onSubmit={handleSubmitUpdate}>
                                <div>
                                    <label htmlFor="name">الاسم:</label>
                                    <input
                                        type="text"
                                        id="display_name"
                                        name="display_name"
                                        value={updatedUser.display_name}
                                        onChange={handleUpdateInputChange}
                                    />
                                    {updateValidationErrors && updateValidationErrors.map(error => (
                                        error.path === 'display_name' && <div className="error">{error.msg}</div>
                                    ))}
                                </div>
                                <div>
                                    <label htmlFor="phone_number">رقم الهاتف:</label>
                                    <input
                                        type="text"
                                        id="phone_number"
                                        name="phone_number"
                                        value={updatedUser.phone_number}
                                        onChange={handleUpdateInputChange}
                                    />
                                    {updateValidationErrors && updateValidationErrors.map(error => (
                                        error.path === 'phone_number' && <div className="error">{error.msg}</div>
                                    ))}
                                </div>
                                <div>
                                    <label htmlFor="email">البريد الإلكتروني:</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={updatedUser.email}
                                        onChange={handleUpdateInputChange}
                                    />
                                    {updateValidationErrors && updateValidationErrors.map(error => (
                                        error.path === 'email' && <div className="error">{error.msg}</div>
                                    ))}
                                </div>

                                <div className={'mt-1'}>
                                    <label htmlFor="role">نوع المستخدم:</label>
                                    <select
                                        className={'form-select'}
                                        id="role"
                                        name="role"
                                        value={updatedUser.role}
                                        onChange={handleUpdateInputChange}
                                    >
                                        <option value="">اختر نوع المستخدم</option>
                                        <option value="ADMIN">مدير</option>
                                        <option value="USER">مستخدم</option>
                                        <option value="COMPANY">جمعية</option>
                                    </select>
                                    {updateValidationErrors && updateValidationErrors.map(error => (
                                        error.path === 'role' && <div className="error">{error.msg}</div>
                                    ))}

                                </div>
                                <button className={'mt-2'} type="button" onClick={updateUser}>تحديث</button>

                                {updateSuccessMessage && <div className="success">{updateSuccessMessage}</div>}

                            </form>
                        </div>
                    </div>
                )}


                {/* Delete User Modal */}
                {showDeleteModal && (
                    <div className="modal">
                        {/* Modal content for deleting user */}
                        <div className="modal-content">
                            <span className="close" onClick={closeDeleteModal}>&times;</span>
                            <h3>حذف المستخدم</h3>
                            {/* Confirm delete user message */}
                            <p>هل أنت متأكد أنك تريد حذف {selectedUser ? selectedUser.display_name : ''}؟</p>
                            <button onClick={() => deleteUser(selectedUser.user_id)}>نعم، حذف</button>

                            {validationDeleteErrors && <div className="error">{validationDeleteErrors}</div>}

                            {deleteSuccessMessage && <div className="success">{deleteSuccessMessage}</div>}

                        </div>
                    </div>

                )}

                <h2 className={'m-3'}>
                    جميع المستخدمين على النظام

                    <button style={{fontSize: "23px"}} className={'float-start'} onClick={openAddModal}>إضافة مستخدم
                        جديد</button>
                </h2>

                <input
                    type="text"
                    placeholder="البحث من خلال الاسمو رقم الهاتف أو البريد الاكتروني"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <table>
                    <thead>
                    <tr>
                        <th>الرقم</th>
                        <th>الاسم</th>
                        <th>البريد الإلكتروني</th>
                        <th>رقم الهاتف</th>
                        <th>تاريخ التسجيل</th>
                        <th>نوع المستخدم</th>
                        <th>التحكم</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map((user, index) => (
                        <tr key={index}>
                            <td>{index + 1 + (currentPage - 1) * usersPerPage}</td>
                            <td>{user.display_name}</td>
                            <td>{user.email}</td>
                            <td>{user.phone_number}</td>
                            <td>{(user.created_at).slice(0, 10)}</td>
                            <td><RoleComponent role={(user.role)}/></td>
                            <td style={{margin: "5px"}}>
                                <button style={{margin: "0 5px"}} onClick={() => openUpdateModal(user.user_id)}>تحديث
                                </button>
                                <button onClick={() => openDeleteModal(user.user_id)}>حذف</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <ul className="pagination mt-3 cursor-pointer">
                    {/* First Page Button */}
                    <li>
                        <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>&laquo;</button>
                    </li>
                    {/* Previous Page Button */}
                    <li>
                        <button onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                                disabled={currentPage === 1}>&lt;</button>
                    </li>
                    {/* Display a range of pages around the current page */}
                    {Array.from({length: totalPages > 5 ? 5 : totalPages}).map((_, index) => {
                        const page = index + 1;
                        const pageToShow = currentPage <= 3 ? page : currentPage + index - 2;
                        return pageToShow <= totalPages ? (
                            <li key={index}>
                                <button
                                    onClick={() => handlePageChange(pageToShow)}
                                    className={currentPage === pageToShow ? "active" : ""}
                                >
                                    {pageToShow}
                                </button>
                            </li>
                        ) : null;
                    })}
                    {/* Next Page Button */}
                    <li>
                        <button onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                                disabled={currentPage === totalPages}>&gt;</button>
                    </li>
                    {/* Last Page Button */}
                    <li>
                        <button onClick={() => handlePageChange(totalPages)}
                                disabled={currentPage === totalPages}>&raquo;</button>
                    </li>
                </ul>
            </AdminLayout>
        </div>
    )
        ;
};

export default UserList;
