import React, { useState, useEffect } from 'react';
import {jwtDecode} from "jwt-decode";

const PasswordChange = () => {
    const [formData, setFormData] = useState({
        // currentPassword: '',
        password: '',
        r_password: ''
    });
    const [user_id, setUser_id] = useState(0);
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (user_id === 0) {
            return;
        }
        setSuccess("");
        setErrors({});


            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/${user_id}/password`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('ALANEES_accessToken')}`
                    },
                    body: JSON.stringify({ password: formData.password, r_password: formData.r_password })
                });

                if (!response.ok) {
                    const responseData = await response.json();
                    let errors = {};
                    errors = (responseData.errors.reduce((acc, error) => {
                        acc[error.path] = error.msg;
                        return acc;
                    }, {}));

                    setErrors(errors);
                } else {
                    setSuccess("تم تحديث الرقم السري بنجاح");
                    setFormData({
                        password: '',
                        r_password: ''
                    });
                }
            } catch (error) {
                console.error('Password change failed:', error.message);
            }

    };

    useEffect(() => {
        const storedToken = localStorage.getItem('ALANEES_accessToken');

        if (storedToken) {
            try {
                const decodedToken = jwtDecode(storedToken);
                setUser_id(decodedToken.user.user_id);
            } catch (error) {
                console.error('Error decoding token:', error);
            }
        }

    }, []);

    return (
        <div dir={'rtl'}>
            <h2>تغيير كلمة المرور</h2>
            <form onSubmit={handleSubmit}>
                {/*<div className={'row'}>*/}
                {/*    <div className="col-12 mb-3">*/}
                {/*        <label htmlFor="currentPassword" className="form-label">كلمة المرور الحالية:</label>*/}
                {/*        <input type="password" className="form-control" id="currentPassword" name="currentPassword" value={formData.currentPassword} onChange={handleChange} />*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className={'row'}>
                    <div className=" col-12 col-sm-6 mb-3">
                        <label htmlFor="password" className="form-label">كلمة المرور الجديدة:</label>
                        <input type="password" className="form-control" id="password" name="password" value={formData.password} onChange={handleChange} />
                        {errors.password && <div className="text-danger mt-1">{errors.password}</div>}

                    </div>
                    <div className="col-12 col-sm-6 mb-3">
                        <label htmlFor="r_password" className="form-label">تأكيد كلمة المرور الجديدة:</label>
                        <input type="password" className="form-control" id="r_password" name="r_password" value={formData.r_password} onChange={handleChange} />
                        {errors.r_password && <div className="text-danger mt-1">{errors.r_password}</div>}
                    </div>

                </div>

                <button type="submit" className="btn btn-primary">تغيير كلمة المرور</button>

                {success && <p className="text-success mt-2">{success}</p>}
            </form>
        </div>
    );
};

export default PasswordChange;
