import React, { useState, useEffect } from 'react';
import AdminLayout from "../layout/adminLayout";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const OrderList = () => {
    const [orders, setOrders] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('');
    const [selectedNote, setSelectedNote] = useState('');
    const [showOrderModal, setOrderModal] = useState(false);

    const [selectedOrderDetails, setSelectedOrderDetails] = useState(null); // New state to hold selected order details

    useEffect(() => {
        // Fetch order data from API
        const fetchOrders = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}api/orders?inquiry=${searchQuery}&page=${currentPage}&status=${selectedStatus}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch orders');
                }
                const data = await response.json();
                setOrders(data.orders);
                setTotalPages(data.totalPages);
                setCurrentPage(data.currentPage);
                setTotalCount(data.totalCount);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, [searchQuery, currentPage, selectedStatus]);

    // Function to handle search query change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Function to handle pagination
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const readFileAsBase64 = (filePath) => {
        return new Promise((resolve, reject) => {
            fetch(filePath)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch file');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                })
                .catch(reject);
        });
    };

    // Function to open modal and set selected order ID
    const handleOpenModal = (orderId, paymentStatus, note) => {
        setSelectedOrderId(orderId);
        setSelectedPaymentStatus(paymentStatus);
        setSelectedNote(note);
        setShowModal(true);
    };


    const generatePDF = async (order_id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/orders/${order_id}`);
            if (!response.ok) {
                throw new Error('Failed to fetch orders');
            }
            const result = await response.json();

            let text_title = result.result.service.service_name + " - " + result.result.service_variant.service_variant_name + " - " + result.result.services_beneficiary.beneficiaries_type ;
            let text_sub_title = [] ;

            // Extract services information from the result
            const servicesInfo = JSON.parse(result.result.services_information_required);

            let imageVariable = null;

            let logo = '/logo.png';
            let phone = '0972549507745';

            if (result.result.logo) {
                const file = `${process.env.REACT_APP_API_URL}${result.result.logo.path.replace(/\\/g, '/')}`
                logo = file;
            }

            if (result.result.user.role === "COMPANY") {
                phone = result.result.user.phone_number;
            }


            let keys = Object.keys(servicesInfo);
            for (let i=0; i<keys.length; i++) {
                if (servicesInfo[keys[i]].includes('data:image')) {
                    imageVariable = servicesInfo[keys[i]];
                } else {
                    text_sub_title.push(`${keys[i]} - ${servicesInfo[keys[i]]} `);
                }
            }


            // Create a new window for printing
            const printWindow = window.open('', '_blank');

            // Construct the HTML content for printing
            const content = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet">
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"></script>
                <title>إنشاء بطاقة</title>
                <style>
                    canvas {
                        border: 1px solid #000;
                    }
                </style>
            </head>
            <body style="text-align: center;">
                <h1>عرض الصورة</h1>
                <canvas id="cardCanvas" width="600" height="400"></canvas>
                
                
                
                
<!--                <div>-->
<!--                    <button class="btn btn-info col-2 text-white" onclick="downloadCanvas()">تنزيل الصورة</button>-->
<!--                </div>-->
                <script>
                    generateCard();

                    function generateCard() {
                        var canvas = document.getElementById('cardCanvas');
                        var ctx = canvas.getContext('2d');

                        var img = new Image();
                        img.onload = function() {
                            ctx.fillStyle = '#fff'; // Background color
                            ctx.fillRect(0, 0, canvas.width, canvas.height);
                            // Draw the uploaded image
                            var maxWidth = 200;
                            var aspectRatio = img.width / img.height;
                            var newWidth = Math.min(maxWidth, img.width);
                            // var newHeight = newWidth / aspectRatio;
                             var newHeight  =  200;
                
                            // Draw uploaded image
                            var uploadedImageX = (canvas.width - newWidth) / 2;
                            var uploadedImageY = 20; // Position image at the top
                            ctx.drawImage(img, uploadedImageX, uploadedImageY, newWidth, newHeight);
                        
                            
                            // Draw default image 1 (top left corner)
            var defaultImage1 = new Image();
            defaultImage1.src = '${logo}'; // Path to your default image
            defaultImage1.onload = function() {
                var defaultImageWidth = 55; // Adjust the width of the default image as needed
                var defaultImageHeight = defaultImage1.width / (defaultImage1.width / defaultImageWidth);
                var padding = 10; // Padding from the edges
                var defaultImage1X = padding;
                var defaultImage1Y = padding;
                ctx.drawImage(defaultImage1, defaultImage1X, defaultImage1Y, defaultImageWidth, defaultImageHeight);
            };

            // Draw default image 2 (top right corner)
            var defaultImage2 = new Image();
            defaultImage2.src = '${logo}'; // Path to your default image
            defaultImage2.onload = function() {
                var defaultImageWidth = 55; // Adjust the width of the default image as needed
                // var defaultImageHeight = defaultImage2.width / (defaultImage2.width / defaultImageWidth);
                var defaultImageHeight = defaultImage2.width / (defaultImage2.width / defaultImageWidth);
                var padding = 10; // Padding from the edges
                var defaultImage2X = canvas.width - defaultImageWidth - padding;
                var defaultImage2Y = padding;
                ctx.drawImage(defaultImage2, defaultImage2X, defaultImage2Y, defaultImageWidth, defaultImageHeight);
            };

            // Text
            ctx.fillStyle = '#000';
            ctx.font = '16px Arial';
            ctx.textAlign = 'center';
            ctx.fillText('${text_title}', canvas.width / 2, 270);
            
            // Start drawing subtitles at y-coordinate 300, incrementing y by 20 for each line
           ${text_sub_title.map((subtitle, index) => `
                                ctx.fillText('${subtitle}', canvas.width / 2, 300 + ${index} * 20);
                            `).join('')}

            // Text2 (centered at the bottom with blue background)
            var text2 = 'التواصل عن طريق ${phone}';
            var textWidth = ctx.measureText(text2).width; // Measure the width of the text
            var padding = 10; // Padding around the text
            var rectY = canvas.height - 20 - 16 - padding / 2; // Y-coordinate for the rectangle (background)
            var rectWidth = canvas.width - padding; // Width of the rectangle (background)
            var rectHeight = 20 + padding; // Height of the rectangle (background)
            var rectX = (canvas.width - rectWidth) / 2; // X-coordinate for the rectangle (background)
            var textX = canvas.width / 2; // X-coordinate for the text
            var textY = canvas.height - 20; // Y-coordinate for the text

            ctx.fillStyle = '#198db6'; // Blue background color
            ctx.fillRect(rectX, rectY, rectWidth, rectHeight); // Draw the background rectangle
            ctx.fillStyle = '#fff'; // Text color
            ctx.textAlign = 'center'; // Center-align the text
            ctx.fillText(text2, textX, textY); // Draw the text
                        
                        
                        
                        
                        
                        
                        
                        };
                        // Set the image source
                        img.src = '${imageVariable}';
                    }

                    
                    function checkCanvas() {
                        try{
                            var canvas = document.getElementById('cardCanvas');
                        var url = canvas.toDataURL('image/png');
                        if (url) return true;
                        } catch (e) {
                            return false;
                        }
                    }
                    
                    
                    function downloadCanvas() {
                        var canvas = document.getElementById('cardCanvas');
                        var url = canvas.toDataURL('image/png');

                        // Create a temporary link element
                        var link = document.createElement('a');
                        link.href = url;
                        link.download = 'card.png';

                        // Trigger the download
                        link.click();
                    }
                </script>
            </body>
            </html>
        `;

            // Write the content to the print window
            printWindow.document.write(content);

            // Close the print window after printing
            printWindow.document.close();

        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    }

    const showDetails = async (order_id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/orders/${order_id}`);
            if (!response.ok) {
                throw new Error('Failed to fetch orders');
            }
            const result = await response.json();
            setSelectedOrderDetails(result);
            setOrderModal(true);
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    }



    // Function to close modal
    const handleCloseModal = () => {
        setSelectedPaymentStatus('');
        setSelectedNote('');
        setShowModal(false);
        setSelectedOrderDetails(null);
        setOrderModal(false);
    };

    // Function to change payment status with a note
    const handleChangePaymentStatus = async () => {
        try {
            const requestOptions = {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ payment_status: selectedPaymentStatus, note: selectedNote })
            };
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/orders/${selectedOrderId}`, requestOptions);
            if (!response.ok) {
                throw new Error('Failed to update payment status');
            }
            // Update the orders list with the updated order
            const updatedOrders = orders.map(order => {
                if (order.order_id === selectedOrderId) {
                    return { ...order, payment_status: selectedPaymentStatus, note: selectedNote }; // Update note here
                }
                return order;
            });
            setOrders(updatedOrders);
            handleCloseModal();
        } catch (error) {
            console.error('Error updating payment status:', error);
        }
    };


    const renderOrderDetailsModal = () => {
        if (!selectedOrderDetails) {
            return null;
        }

        // Extract relevant order details
        const {
            user,
            service,
            service_variant,
            services_information_required,
            order_date,
            price,
            payment_status,
            note
        } = selectedOrderDetails.result;

        // Extract services information from the result
        const servicesInfo = JSON.parse(services_information_required);

        return (
            <Modal show={showOrderModal} onHide={handleCloseModal} className={'reserved_modal'}>
                <Modal.Header closeButton>
                    <Modal.Title>تفاصيل الطلب</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>اسم العميل: </strong>{user.display_name}</p>
                    <p><strong>اسم الخدمة: </strong>{service.service_name}</p>
                    <p><strong>اسم النوع: </strong>{service_variant.service_variant_name}</p>
                    <p><strong>تفاصيل الخدمة: </strong></p>
                    <ul>
                        {Object.entries(servicesInfo).map(([key, value]) => (
                            <li key={key}>
                                <strong>{key}: </strong>
                                {value.startsWith('data:image') ? <img src={value} alt={key} style={{ maxWidth: '100%' }} /> : value}
                            </li>
                        ))}
                    </ul>
                    <p><strong>تاريخ الطلب: </strong>{order_date.substring(0, 10)}</p>
                    <p><strong>السعر: </strong>{price}</p>
                    <p><strong>حالة الطلب: </strong>

                        {payment_status === 'PENDING' && (
                            <span className="badge bg-warning">قيد الانتظار</span>
                        )}
                        {payment_status === 'PAID' && (
                            <span className="badge bg-success">تم الدفع</span>
                        )}
                        {payment_status === 'CANCELLED' && (
                            <span className="badge bg-danger">تم الغاء العملية</span>
                        )}


                    </p>
                    <p><strong>ملاحظة: </strong>{note}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>إغلاق</Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <div className="user-list-container">
            <AdminLayout>
                <h1>قائمة الطلبات</h1>

                {/* Filter dropdown */}
                <div className="row">
                    {/* Search input */}
                    <div className="col">
                        <label htmlFor="searchInput" className="form-label">البحث في الطلبات:</label>
                        <input
                            id="searchInput"
                            className="form-control"
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="ابحث في الطلبات..."
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="statusSelect" className="form-label">حالة الطلب:</label>
                        <select
                            id="statusSelect"
                            className="form-select"
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                        >
                            <option value="">الجميع</option>
                            <option value="PENDING">قيد الانتظار</option>
                            <option value="PAID">تم الدفع</option>
                            <option value="CANCELLED">تم الغاء العملية</option>
                        </select>
                    </div>
                </div>

                {/* Order list */}
                <table>
                    <thead>
                    <tr>
                        <th>اسم العميل</th>
                        <th>اسم الطلب - اسم النوع</th>
                        <th>رقم الهاتف</th>
                        <th>تاريخ الطلب</th>
                        <th>السعر</th>
                        <th>حالة الطلب</th>
                        <th>العمليات</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orders.map(order => (
                        <tr key={order.order_id}>
                            <td>{order.user.display_name}</td>
                            <td>{order.service.service_name} - {order.service_variant.service_variant_name}</td>
                            <td>

                                <a href={`https://wa.me/00972${order.user.phone_number}`} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
                                    <i className="fab fa-whatsapp"></i>
                                </a>

                                {order.user.phone_number}
                            </td>
                            <td>{(order.order_date).substring(0, 10)}</td>
                            <td>{order.price}</td>
                            <td>
                                {order.payment_status === 'PENDING' && (
                                    <span className="badge bg-warning">قيد الانتظار</span>
                                )}
                                {order.payment_status === 'PAID' && (
                                    <span className="badge bg-success">تم الدفع</span>
                                )}
                                {order.payment_status === 'CANCELLED' && (
                                    <span className="badge bg-danger">تم الغاء العملية</span>
                                )}
                            </td>
                            <td>
                                <button className="btn btn-primary mx-2" onClick={() => handleOpenModal(order.order_id, order.payment_status, order.note)}>تغيير حالة الدفع</button>
                                <button className="btn btn-primary mx-2" onClick={() => generatePDF(order.order_id)}>إنشاء ال PDF</button>
                                <button className="btn btn-primary mx-2" onClick={() => showDetails(order.order_id)}>معلومات الطلب</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>


                {renderOrderDetailsModal()}
                {/* Modal for changing payment status */}
                <Modal show={showModal} onHide={handleCloseModal} className={'reserved_modal'}>
                    <Modal.Header closeButton>
                        <Modal.Title>تغيير حالة الدفع</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <select
                            className="form-select mb-3"
                            value={selectedPaymentStatus}
                            onChange={e => setSelectedPaymentStatus(e.target.value)}
                        >
                            <option value="PENDING">قيد الانتظار</option>
                            <option value="PAID">تم الدفع</option>
                            <option value="CANCELLED">تم الغاء العملية</option>
                        </select>
                        <textarea
                            className="form-control"
                            value={selectedNote}
                            onChange={e => setSelectedNote(e.target.value)}
                            placeholder="ملاحظة (اختياري)"
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>إلغاء</Button>
                        <Button variant="primary" onClick={handleChangePaymentStatus}>حفظ التغييرات</Button>
                    </Modal.Footer>
                </Modal>

                <ul className="pagination mt-3 cursor-pointer">
                    {/* First Page Button */}
                    <li>
                        <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>&laquo;</button>
                    </li>
                    {/* Previous Page Button */}
                    <li>
                        <button onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                                disabled={currentPage === 1}>&lt;</button>
                    </li>
                    {/* Display a range of pages around the current page */}
                    {Array.from({length: totalPages > 5 ? 5 : totalPages}).map((_, index) => {
                        const page = index + 1;
                        const pageToShow = currentPage <= 3 ? page : currentPage + index - 2;
                        return pageToShow <= totalPages ? (
                            <li key={index}>
                                <button
                                    onClick={() => handlePageChange(pageToShow)}
                                    className={currentPage === pageToShow ? "active" : ""}
                                >
                                    {pageToShow}
                                </button>
                            </li>
                        ) : null;
                    })}
                    {/* Next Page Button */}
                    <li>
                        <button onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                                disabled={currentPage === totalPages}>&gt;</button>
                    </li>
                    {/* Last Page Button */}
                    <li>
                        <button onClick={() => handlePageChange(totalPages)}
                                disabled={currentPage === totalPages}>&raquo;</button>
                    </li>
                </ul>
            </AdminLayout>
        </div>
    );
};

export default OrderList;
