import React, {useState} from 'react';
import AdminLayout from "../layout/adminLayout";
import {FaMoneyBillWave, FaCheckCircle, FaTimesCircle, FaClock, FaPrint} from 'react-icons/fa';

const ReportList = () => {
    const [fromDate, setFromDate] = useState('');
    const [errorFromDate, setErrorFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [errorToDate, setErrorToDate] = useState('');
    const [orders, setOrders] = useState([]);
    const [profit, setProfit] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);


    // Function to handle filter changes
    const handleFilterChange = (filterType, value) => {
        if (filterType === 'fromDate') {
            setErrorFromDate('');
            setFromDate(value);
        } else if (filterType === 'toDate') {
            setErrorToDate('');
            setToDate(value);
        }
    };

    // Function to handle box click
    const handleBoxClick = (payment_status) => {
        if (!fromDate) {
            setErrorFromDate('يجب اختيار التاريخ');
        }
        if (!toDate) {
            setErrorToDate('يجب اختيار التاريخ');
        }

        if (!fromDate || !toDate) {
            return;
        }

        const apiUrl = !payment_status ? '/reports/profit' : '/reports/payments';
        const queryParams = !payment_status ?
            `?from_date=${fromDate}&to_date=${toDate}` :
            `?from_date=${fromDate}&to_date=${toDate}&payment_status=${payment_status}`;

        // Call API using fetch
        fetch(`${process.env.REACT_APP_API_URL}api${apiUrl}${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                // Handle API response
                console.log(data);
                setOrders(data.result.orders);
                if (data.result.totalProfit) {

                    setProfit(data.result.totalProfit);
                    setTotalPrice(data.result.totalPrice);
                } else {
                    setProfit(0);
                    setTotalPrice(0);
                }

                // Open new window with sample data for printing
                // window.open('/sample-report', '_blank');
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handlePrint = () => {
        if (orders.length === 0) {
            console.log('No orders to print');
            return;
        }

        // Create a new window for printing
        const printWindow = window.open('', '_blank');

        // Construct the HTML content for printing
        const content = `
   
    <html lang="ar">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Print Report</title>
        <style>
            /* Print styles */
            @media print {
                     .text-center {
            text-align: center;
        }

        

        @media print {
            @page { margin: 5px}
            body {
                margin: 1.2cm;
                transform: translateX(50px); /* Adjust as needed */
            }

            html, body {
                width: 210mm;
                height: 297mm;
            }

            .show-print {
                display: block !important;
            }

            .hide-print {
                display: none;
            }

            
            .gray-back {
                background-color: lightgray;
            }
body {
                    font-family: Arial, sans-serif;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    /*margin-top: 60px;*/
                    margin-right: 10px;
                }
                th, td {
                    border: 1px solid #dee2e6;
                    padding: 0.5rem;
                    text-align: center;
                }
                th {
                    background-color: #f0f0f0;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                tr:hover {
                    background-color: #e2e2e2;
                }
        }
        
        </style>
    </head>
    <body dir="rtl">
       
        <div class="header">
           

             <div class="middle-header text-center col-12" align="center" width="50" >
                    <img style="width: 150px; height: auto" src="/logo.png">
                </div>
                
    
            </div>

        </div>
        <table>
            <thead>
                <tr>
                    <th>اسم العميل</th>
                    <th>اسم الطلب - اسم النوع</th>
                    <th>تاريخ الطلب</th>
                    <th>السعر</th>
                    <th>حالة الطلب</th>
                    <th>ملاحظات</th>
                </tr>
            </thead>
            <tbody>
                ${orders.map(order => `
                    <tr>
                        <td>${order.display_name}</td>
                        <td>${order.service_name} - ${order.service_variant_name}</td>
                        <td>${new Date(order.order_date).toLocaleDateString()}</td>
                        <td>${order.price}</td>
                         <td>
                            ${order.payment_status === 'PENDING' ? '<span class="badge bg-warning">قيد الانتظار</span>' : ''}
                            ${order.payment_status === 'PAID' ? '<span class="badge bg-success">تم الدفع</span>' : ''}
                            ${order.payment_status === 'CANCELLED' ? '<span class="badge bg-danger">تم الغاء العملية</span>' : ''}
                        </td>
                        <td>${order.note ? order.note : ''}</td>
                    </tr>
                `).join('')}
            </tbody>
        </table>
        
        <table className={'mt-5'}>
            <tbody>
                ${profit ? `
                    <tr>
                        <td>إجمالي البيع</td>
                        <td>${totalPrice}</td>
                        <td>إجمالي الربح</td>
                        <td>${profit}</td>
                    </tr>
                ` : ''}
            </tbody>
        </table>
        
        
        <script>
            // Trigger the print dialog
            window.print();
        </script>
    </body>
    </html>
`;


        // Write the content to the print window
        printWindow.document.write(content);

        // Close the print window after printing
        printWindow.document.close();
    };


    return (
        <div className="user-list-container">
            <AdminLayout>
                {/* Date Range Filter */}
                <div className={'row'}>
                    <div className={'col'}>
                        <label>من تاريخ :</label>
                        <input className={'text-right'} type="date" value={fromDate}
                               onChange={e => handleFilterChange('fromDate', e.target.value)}/>

                        <span className={'error'}> {errorFromDate}</span>
                    </div>
                    <div className={'col'}>
                        <label>إلى تاريخ :</label>
                        <input className={'text-right'} type="date" value={toDate}
                               onChange={e => handleFilterChange('toDate', e.target.value)}/>
                        <span className={'error'}> {errorToDate}</span>

                    </div>
                </div>

                {/* Report Widgets */}
                <div className={'row mt-4'}>
                    <div className={'col-6 mt-3 '} onClick={() => handleBoxClick('')}>
                        <div className={'card text-center cursor-pointer'}>
                            <div className={'card-body'}>
                                <FaMoneyBillWave size={200}/>
                                <h5 className={'card-title'}>الأرباح</h5>
                            </div>
                        </div>
                    </div>
                    <div className={'col-6 mt-3 '} onClick={() => handleBoxClick('PAID')}>
                        <div className={'card text-center cursor-pointer'}>
                            <div className={'card-body'}>
                                <FaCheckCircle size={200}/>
                                <h5 className={'card-title'}>الطلبات المدفوعة</h5>
                            </div>
                        </div>
                    </div>
                    <div className={'col-6 mt-3 '} onClick={() => handleBoxClick('CANCELLED')}>
                        <div className={'card text-center cursor-pointer'}>
                            <div className={'card-body'}>
                                <FaTimesCircle size={200}/>
                                <h5 className={'card-title'}>الطلبات الملغية</h5>
                            </div>
                        </div>
                    </div>
                    <div className={'col-6 mt-3 '} onClick={() => handleBoxClick('PENDING')}>
                        <div className={'card text-center cursor-pointer'}>
                            <div className={'card-body'}>
                                <FaClock size={200}/>
                                <h5 className={'card-title'}>الطلبات قيد الانتظار</h5>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Orders Table */}
                <div className="row mt-4">
                    <div className="col">
                        <h3>التقرير</h3>
                        <button className="btn btn-primary mb-3" onClick={handlePrint}>
                            <FaPrint className="mr-1"/> طباعة التقرير
                        </button>
                        <table>
                            <thead>
                            <tr>
                                <th>اسم العميل</th>
                                <th>اسم الطلب - اسم النوع</th>
                                <th>تاريخ الطلب</th>
                                <th>السعر</th>
                                <th>حالة الطلب</th>
                                <th>ملاحظات</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orders.map(order => (
                                <tr key={order.order_id}>
                                    <td>{order.display_name}</td>
                                    <td>{order.service_name + ' - ' + order.service_variant_name}</td>
                                    <td>{new Date(order.order_date).toLocaleDateString()}</td>
                                    <td>{order.price}</td>
                                    <td>
                                        {order.payment_status === 'PENDING' && (
                                            <span className="badge bg-warning">قيد الانتظار</span>
                                        )}
                                        {order.payment_status === 'PAID' && (
                                            <span className="badge bg-success">تم الدفع</span>
                                        )}
                                        {order.payment_status === 'CANCELLED' && (
                                            <span className="badge bg-danger">تم الغاء العملية</span>
                                        )}
                                    </td>
                                    <td>{order.note}</td>
                                </tr>
                            ))}




                            </tbody>
                        </table>


                        <table className={'mt-5'}>
                            <tbody>
                            { profit ?
                                <tr>
                                    <td>إجمالي البيع</td>
                                    <td>{totalPrice}</td>
                                    <td>إجمالي الربح</td>
                                    <td>{profit}</td>

                                </tr> : ''}
                            </tbody>
                        </table>


                    </div>
                </div>

            </AdminLayout>
        </div>
    );
};

export default ReportList;



