import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

const ForgetPassword = () => {
    const [formData, setFormData] = useState({ email: "" });
    const [error, setError] = useState("");
    const [confirm, setConfirm] = useState("");
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        if (!formData.email) {
            setError("البريد الإلكتروني مطلوب");
            return;
        }

        try {
            setLoading(true);
            const domain = window.location.origin;
            await axios.get(`${process.env.REACT_APP_API_URL}api/userEmail`, {
                params: { email: formData.email },
                headers: {
                    'Domain': domain
                }
            });            setError("");
            setConfirm("تم ارسال رسالة إلى بريدك الإلكتروني");
        } catch (err) {
            setConfirm("");
            setError(err.response?.data?.message || 'البريد الإلكتروني غير صحيح');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="text-center">
                        <h3 className="mb-4" style={{ fontFamily: 'Cairo, sans-serif', fontWeight: 'bold' }}>
                            إعادة تعيين كلمة المرور
                        </h3>
                    </div>
                    <div className="card" dir="rtl">
                        <div className="card-body">
                            <div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">البريد الإلكتروني</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder="أدخل بريدك الإلكتروني"
                                        onChange={handleChange}
                                    />
                                </div>
                                {error && <span className='text-danger'>{error}</span>}
                                {confirm && <span className='text-success'>{confirm}</span>}
                            </div>
                            <div className="d-flex justify-content-center">
                                <button
                                    type="button"
                                    className="btn shining"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                    style={{
                                        background: 'linear-gradient(to right, rgb(245, 227, 102), rgb(255, 209, 102), rgb(255, 180, 102), rgb(255, 153, 102))',
                                        color: '#fff',
                                        border: 'none',
                                        fontFamily: 'Cairo, sans-serif',
                                        fontWeight: 'bold',
                                        width: '50%' // Adjust this value as needed
                                    }}
                                >
                                    {loading ? (
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            {' '}جارٍ الإرسال...
                                        </>
                                    ) : 'إرسال'}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
