import React, {useState, useEffect} from 'react';
import AdminLayout from "../layout/adminLayout";
import { Bar } from 'react-chartjs-2';
import "./Dashboard.css"; // Import your CSS file for styling
import { Chart } from 'chart.js/auto'; // Import Chart.js auto bundle

function Dashboard() {

    const [chartData1, setChartData1] = useState(null);
    const [chartData2, setChartData2] = useState(null);

    const [totalUsers, setTotalUsers] = useState(0);
    const [totalCompanies, setTotalCompanies] = useState(0);


    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}api/statistics`)
            .then(response => response.json())
            .then(data => {


                setChartData1({
                    labels: [
                        'يناير',
                        'فبراير',
                        'مارس',
                        'أبريل',
                        'مايو',
                        'يونيو',
                        'يوليو',
                        'أغسطس',
                        'سبتمبر',
                        'أكتوبر',
                        'نوفمبر',
                        'ديسمبر'
                    ],
                    datasets: [
                        {
                            label: 'المستخدمون',
                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                            borderColor: 'rgba(255, 99, 132, 1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255, 99, 132, 0.4)',
                            hoverBorderColor: 'rgba(255, 99, 132, 1)',
                            data: data.usersByMonth,
                        },
                    ],
                });

                setChartData2({
                    labels: [
                        'يناير',
                        'فبراير',
                        'مارس',
                        'أبريل',
                        'مايو',
                        'يونيو',
                        'يوليو',
                        'أغسطس',
                        'سبتمبر',
                        'أكتوبر',
                        'نوفمبر',
                        'ديسمبر'
                    ],
                    datasets: [
                        {
                            label: 'الشركات',
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
                            hoverBorderColor: 'rgba(75, 192, 192, 1)',
                            data: data.CompaniesByMonth,
                        },
                    ],
                });

                setTotalUsers(data.totalUsers);
                setTotalCompanies(data.totalCompanies);

            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);


    const chartData3 = {
        labels: [
            'يناير',
            'فبراير',
            'مارس',
            'أبريل',
            'مايو',
            'يونيو',
            'يوليو',
            'أغسطس',
            'سبتمبر',
            'أكتوبر',
            'نوفمبر',
            'ديسمبر'
        ],
        datasets: [
            {
                label: 'الأرباح',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(54, 162, 235, 0.4)',
                hoverBorderColor: 'rgba(54, 162, 235, 1)',
                data: [65, 59, 80, 81, 56, 55, 40, 70, 75, 90, 85, 60],
            },
        ],
    };


    return (
        <div className="App">
            <AdminLayout>
                <div className="dashboard-cards">
                    <div className="dashboard-card">
                        <h2 className="card-title">عدد الإجمالي للمستخدمين</h2>
                        <p className="card-description">{totalUsers}</p>
                        {chartData1 && <Bar data={chartData1} />}
                    </div>
                    <div className="dashboard-card">
                        <h2 className="card-title">عدد الإجمالي للجمعيات</h2>
                        <p className="card-description">{totalCompanies}</p>
                        {chartData2 && <Bar data={chartData2} />}
                    </div>

                </div>

                {/*<div className="dashboard-cards">*/}
                {/*    <div className="dashboard-card">*/}
                {/*        <h2 className="card-title">الأرباح الشهرية</h2>*/}
                {/*        <p className="card-description"></p>*/}
                {/*        <Bar data={chartData3} />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </AdminLayout>
        </div>
    );
}

export default Dashboard;
