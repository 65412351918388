


export const navLinks = [
    {
        id: '',
        title: 'أعمالنا السابقة والأسئلة الشائعة',
        to: '/OurWork'
    },
    {
        id: '',
        title: 'من نحن',
        to: '/commonQuestions'

    },
    {
        id: '',
        title: 'تواصل معنا',
        to: '/contactUs'

    },
    {
        id: '',
        title: 'الرئيسية',
        to: '/'

    }
];


