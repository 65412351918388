import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './register.css';
import { useAuth } from '../../../AuthContext';

const Register = () => {
    const navigate = useNavigate();
    const { register } = useAuth();
    const [formData, setFormData] = useState({
        display_name: "",
        email: "",
        password: "",
        phone_number: "",
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const success = await register(formData);
        if (success === true) {
            navigate('/');
        } else {
            setErrors(success);
            // setErrors({ registration: "Registration failed" });
        }
    };

    return (
        <section className="h-full bg-light">
            <div className="container h-full p-5">
                <div className="row h-full align-items-center justify-content-center text-dark">
                    <div className="col-lg-6" id={'registerSection'}>

                        <span className="d-inline-flex align-items-center mx-2 " dir="rtl" id={'homePageIcon'}>
                            <Link className="d-inline-block" to="/">
                                <i aria-hidden="true" className="fa fa-home"
                                   style={{fontSize: "27px"}}
                                ></i></Link>
                        </span>


                        <div className="block rounded bg-white shadow-lg">
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="mx-4 my-4">
                                        <div className="text-center">
                                            <img
                                                className="mx-auto img-fluid"
                                                style={{ width: "250px" }}
                                                src="/logo.png"
                                                alt="logo"
                                            />
                                        </div>

                                        <div className="row d-flex justify-content-center" dir={'rtl'}>
                                            <form onSubmit={handleSubmit} className={'col-9'}>
                                                <p className="mb-4">صفحة تسجيل حساب جديد</p>

                                                <div className={'row'}>
                                                    <input type={'text'} className={'form-control'} placeholder="ادخل الاسم" name="display_name" onChange={handleChange}/>
                                                    {errors.display_name && <span className="text-danger">{errors.display_name}</span>}
                                                </div>

                                                <div className={'row'}>
                                                    <input type={'text'} className={'form-control mt-2'} placeholder="ادخل رقم الهاتف" name="phone_number" onChange={handleChange}/>
                                                    {errors.phone_number && <span className="text-danger">{errors.phone_number}</span>}
                                                </div>

                                                <div className={'row'}>
                                                    <input type={'email'} className={'form-control mt-2'} placeholder="ادخل البريد الالكتروني" name="email" onChange={handleChange}/>
                                                    {errors.email && <span className="text-danger">{errors.email}</span>}
                                                </div>

                                                <div className={'row'}>
                                                    <input type={'password'} className={'form-control mt-2'} placeholder="ادخل الرقم السري" name="password" onChange={handleChange}/>
                                                    {errors.password && <span className="text-danger">{errors.password}</span>}
                                                </div>


                                                <div className="row mb-4 text-center mt-3">
                                                    <button
                                                        className="mb-3 btn  btn-block shining"
                                                        type="submit"
                                                        style={{
                                                            background:
                                                                "linear-gradient(to right, rgb(245, 227, 102), rgb(255, 209, 102), rgb(255, 180, 102), rgb(255, 153, 102))\n",
                                                        }}
                                                    >
                                                        تسجيل حساب جديد
                                                    </button>
                                                </div>

                                                {/* Register button */}
                                                <div className="d-flex justify-content-between mb-4">
                                                    <p className="mb-0 mr-2">هل لديك حساب ؟</p>
                                                    <Link
                                                        to="/login"
                                                        className="btn btn-outline-warning text-black shining"
                                                    >
                                                        تسجيل دخول
                                                    </Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Register;
