import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    useEffect(() => {
        console.log(window.location.origin)
    }, []);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const location = useLocation();
    const navigate = useNavigate();

    const handleSubmit = () => {
        if (!newPassword || !confirmPassword) {
            setError("كلمة المرور مطلوبة");
            return;
        }

        if (newPassword !== confirmPassword) {
            setError("كلمات المرور غير متطابقة");
            return;
        }

        const token = new URLSearchParams(location.search).get('token');
        axios.patch(`${process.env.REACT_APP_API_URL}api/resetPassword`, { token, newPassword })
            .then((res) => {
                console.log(res)
                setError("");
                setSuccess("تم تحديث كلمة المرور بنجاح. يمكنك الآن تسجيل الدخول.");
                setTimeout(() => navigate('/login'), 2000);
            })
            .catch((err) => {
                console.log(err)
                setSuccess("");
                setError(err.response?.data?.message || 'حدث خطأ. يرجى المحاولة مرة أخرى.');
            });
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card" dir="rtl">
                        <div className="card-body">
                            <h3 className="card-title text-center mb-4">إعادة تعيين كلمة المرور</h3>
                            <div>
                                <div className="mb-3">
                                    <label htmlFor="newPassword" className="form-label">كلمة المرور الجديدة</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="newPassword"
                                        placeholder="أدخل كلمة المرور الجديدة"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="confirmPassword" className="form-label">تأكيد كلمة المرور</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmPassword"
                                        placeholder="أكد كلمة المرور الجديدة"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </div>
                                {error && <span className='text-danger'>{error}</span>}
                                {success && <span className='text-success'>{success}</span>}
                            </div>

                            <div className="d-flex justify-content-center">
                                <button
                                    type="button"
                                    className="btn shining"
                                    onClick={handleSubmit}
                                    style={{
                                        background: 'linear-gradient(to right, rgb(245, 227, 102), rgb(255, 209, 102), rgb(255, 180, 102), rgb(255, 153, 102))',
                                        color: '#fff',
                                        border: 'none',
                                        fontFamily: 'Cairo, sans-serif',
                                        fontWeight: 'bold',
                                        width: '50%'
                                    }}
                                >
                                    تحديث كلمة المرور
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
