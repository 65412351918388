import {motion} from 'framer-motion';
import {useEffect, useState} from 'react';
import './Banner.css';
import {Link, NavLink} from "react-router-dom";
import {navLinks} from "../navbar/NavLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {useAuth} from "../../../../AuthContext";

const Banner = (props) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [active, setActive] = useState('');
    const [toggle, setToggle] = useState(false);
    const { loggedIn, logout, user } = useAuth();
    const display_name = (user && user.display_name) ? user.display_name : localStorage.getItem('ALANEES_displayName');

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div id={'main'}>
            <div className="main_container" style={{height: `${props.height ?? 100}vh`}}>
                <div className={'row'} dir={'rtl'} style={{borderBottom: "1px solid #cccccc61", margin: "-10px", height:"120px"}}>
                    <div className=" col-8 col-sm-6 col-md-4 p-5" style={{textAlign: "center"}}>
                        <div className="header-icon  text-right">
                            <div className="header-contact ">
                                <div className="justify-content-end  links ">
                                    <div className="header-contact-details ms-2" >
                                        {loggedIn ? (
                                            // If logged in, show welcome message and logout option
                                            <div className="">
                                                <span className={'text'}>أهلا {display_name}</span>
                                                <span className={'text mx-2'}> | </span>
                                                <button className={'p-2 pb-2'} onClick={logout}>تسجيل خروج</button>

                                                {user.role === "ADMIN" ? (<NavLink to={'/admin/dashboard'} className={'btn btn-success mx-2'}>صفحة المدير للتحكم</NavLink>) : ''}
                                            </div>
                                        ) : (
                                            // If not logged in, show login and register options
                                            <div id="notLogin" className="">
                                                <NavLink to="/login" className={'d-inline mx-3 text'}>
                                                    <span className={'fa fa-user text'}></span>
                                                </NavLink>
                                                <NavLink className="d-inline" to="/login">
                                                    <h5 className="header-contact-no d-inline-block text">تسجيل دخول</h5>
                                                </NavLink>
                                                <span className="d-inline-block mx-3">|</span>
                                                <NavLink className="d-inline" to="/register">
                                                    <h5 className="header-contact-no d-inline-block text">حساب جديد</h5>
                                                </NavLink>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className={'col-4 col-sm-6 col-md-4'} style={{textAlign: "center"}}>
                        <img src="/logo.png" alt="Logo" className="logo" style={{
                            maxHeight: "auto",
                            maxWidth: "180px",
                            transition: "opacity .4s linear"
                        }}/>
                    </div>

                    <div className={'col-0 col-md-4'}>

                    </div>


                </div>

                <nav className="navbar navbar-expand-lg bg-lg-black" >
                    <div className="container justify-content-center" style={{padding: "0"}}>

                        <div className={'row text-center mt-4'}>
                            <button
                                className="navbar-toggler "
                                type="button"
                                onClick={() => setToggle(!toggle)}
                                style={{color:"#ffffff", fontSize: "22px"}}
                            >
                                <span
                                    className="navbar-toggler-icon"></span> القائمة
                            </button>
                        </div>

                        <div dir={'rtl'} className={`collapse navbar-collapse justify-content-center ${toggle ? 'show' : ''}`} id={'navbar'}>
                            <ul dir={'ltr'} className="navbar-nav mb-2 mb-lg-0" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                {toggle
                                    ? // Render links in reverse order when toggle is active
                                    navLinks
                                        .slice()
                                        .reverse()
                                        .map((nav) => (
                                            <li
                                                key={nav.id}
                                                className={`nav-item m-2 ${active === nav.title ? 'active' : ''} `}
                                                onClick={() => setActive(nav.title)}
                                            >
                                                <NavLink className="nav-link text" style={{ textAlign: "right", color: "#ffffff", fontSize: "22px" }} to={`${nav.to}`}>{nav.title}</NavLink>
                                            </li>
                                        ))
                                    : // Render links in original order for large screens
                                    navLinks.map((nav) => (
                                        <li
                                            key={nav.id}
                                            className={`nav-item m-2 ${active === nav.title ? 'active' : ''} `}
                                            onClick={() => setActive(nav.title)}
                                        >
                                            <NavLink className="nav-link text" style={{ textAlign: "right", color: "#ffffff", fontSize: "22px" }} to={`${nav.to}`}>{nav.title}</NavLink>
                                        </li>
                                    ))}
                                {loggedIn && (
                                    <li
                                        className={`nav-item m-2 ${active === "الملف الشخصي" ? 'active' : ''} `}
                                        onClick={() => setActive('الملف الشخصي')}
                                    >
                                        <NavLink className="nav-link text" style={{ textAlign: "right", color: "#ffffff", fontSize: "22px" }} to={`/profile`}>الملف الشخصي</NavLink>
                                    </li>
                                )}
                            </ul>



                        <div className={`social-media-icons ${!toggle ? '' : 'd-none'}`} style={{margin: "0 0 0 40px"}}>
                                <ul className="navbar-nav mb-2 mb-lg-0">
                                    <li className={'nav-item m-2 mt-3'}>
                                        <a target={'_blank'} href={'https://wa.me/972549507745'}>
                                            <FontAwesomeIcon className={'text'} icon={faWhatsapp} size="1x" />
                                        </a>
                                    </li>

                                    <li className={'nav-item m-2 mt-3'}>
                                        <a href={'https://facebook.com/alanees48'} target={'_blank'}>
                                            <FontAwesomeIcon className={'text'} icon={faFacebook} size="1x" />
                                        </a>
                                    </li>

                                    <li className={'nav-item m-2 mt-3'}>
                                        <a href={'https://www.instagram.com/alanees1948/'} target={'_blank'}>
                                            <FontAwesomeIcon className={'text'} icon={faInstagram} size="1x" />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                </nav>
ٍ
                <div className={'row mt-5'} style={{textAlign: "center", color: "#ffffff"}}>
                    <h1>
                        حجز عمرة بدل وشراء نُسك وحفر الآبار
                    </h1>
                    <h4>
                         خدماتنا تقدم لعرب الداخل في دول أفريقية فقيرة والهند
                    </h4>
                    <h6>
                        نحيا لنغيث الأمل ونعزز الحياة بآبارنا وتقديم الذبائح والنسك في الدول الفقيرة
                    </h6>
                </div>
            </div>
        </div>
    );
};

export default Banner;
