import React from 'react';
import CustomerLayout from "../layout/customerLayout";
import {NavLink, Route, Routes} from 'react-router-dom';

import MyInfo from "./myInfo";

const PersonalInfoContainer = ({children}) => {
    return (
        <div>
            <CustomerLayout height={'70'}>


                <div className="py-3 col-md-12 text" dir={'rtl'}>
                    <ul className="nav nav-tabs mb-3">
                        <li className="nav-item mb-1">
                            <NavLink className="nav-link" to="/profile/MyInfo">البيانات الشخصية</NavLink>
                        </li>
                        <li className="mb-1 nav-item">
                            <NavLink className="nav-link" to="/profile/password">تغير كلمة المرور</NavLink>
                        </li>
                        {/*<li className="mb-1 nav-item">*/}
                        {/*    <NavLink className="nav-link" to="/profile/orders">سلة المشتريات</NavLink>*/}
                        {/*</li>*/}
                    </ul>
                </div>

                <>
                    {children}
                </>

            </CustomerLayout>
        </div>
    );
};

export default PersonalInfoContainer;