import React, {useEffect, useState} from 'react';
import Banner from "../home/component/banner/banner";
import Footer from "../home/component/footer/footer";


function CustomerLayout({ children , height}) {


    return (
        <div>
            <div className={'row'}>
                <Banner height={height}/>
            </div>

            <div className={'mt-4 mx-5'}>
                { children }
            </div>

            <div>
                <Footer />
            </div>

        </div>
    );
}

export default CustomerLayout;