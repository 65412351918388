import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import Router from "./modules/Router/router";
import {AuthProvider} from './AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {


    return (
        <BrowserRouter>
            <AuthProvider>
                <div className="App">
                    <ToastContainer />
                    <div className="relative z-0">
                        <Router/>
                    </div>
                </div>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
