import React, {useState, useEffect} from 'react';
import {jwtDecode} from "jwt-decode";
import {useAuth} from "../../AuthContext";

const MyInfo = () => {
    const { setUser } = useAuth();

    const [formData, setFormData] = useState({
        display_name: '',
        phone_number: '',
        email: '',
    });

    const [user_id, setUser_id] = useState(0);
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState("");
    const [userChanged, setUserChanged] = useState(false);
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleSubmit = async (e) => {

        if (user_id === 0) {
            return;
        }
        setSuccess("");

        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/${user_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    // Assuming you have some authorization header
                    'Authorization': `Bearer ${localStorage.getItem('ALANEES_accessToken')}`
                },
                body: JSON.stringify(formData)
            });
            if (!response.ok) {
                const responseData = await response.json();
                let errors = {};
                errors = (responseData.errors.reduce((acc, error) => {
                    acc[error.path] = error.msg;
                    return acc;
                }, {}));

                setErrors(errors);
            } else {
                // Handle success, maybe show a success message
                setSuccess("تم تحديث البيانات بنجاح");
                localStorage.setItem('ALANEES_display_name', formData.display_name);
                const responseData = await response.json();
                // Update the authentication token if necessary
                const newToken = responseData.accessToken;
                if (newToken) {
                    localStorage.setItem('ALANEES_accessToken', newToken);
                }
                setUserChanged(!userChanged);
                setUser(responseData.user);
                console.log('Update successful');
            }
        } catch (error) {
            console.error('Update failed:', error.message);
        }
    };


    useEffect(() => {
        const storedToken = localStorage.getItem('ALANEES_accessToken');

        if (storedToken) {
            try {
                const decodedToken = jwtDecode(storedToken);
                setFormData(decodedToken.user); // Assuming the decoded token contains user information
                setUser_id(decodedToken.user.user_id);
            } catch (error) {
                console.error('Error decoding token:', error);
            }
        }

    }, [userChanged]);

    return (
        <div dir={'rtl'}>
            <h2>معلومات الحساب</h2>
            <form onSubmit={handleSubmit}>

                <div className={'row'}>
                    <div className=" col-12 col-sm-6 mb-3">
                        <label htmlFor="name" className="form-label">الأسم :</label>
                        <input type="text" className="form-control text-right" id="name" name="display_name"
                               value={formData.display_name} onChange={handleChange}/>
                        {errors.display_name && <span className="text-danger">{errors.display_name}</span>}
                    </div>
                    <div className="col-12 col-sm-6  mb-3">
                        <label htmlFor="phone" className="form-label">رقم الهاتف :</label>
                        <input type="text" className="form-control text-right" id="phone" name="phone_number"
                               value={formData.phone_number} onChange={handleChange}/>
                        {errors.phone_number && <span className="text-danger">{errors.phone_number}</span>}
                    </div>
                </div>

                <div className={'row'}>
                    <div className="col-12 col-sm-6 mb-3">
                        <label htmlFor="email" className="form-label">البريد الالكتروني :</label>
                        <input type="email" className="form-control text-right" id="email" name="email"
                               value={formData.email} onChange={handleChange}/>
                        {errors.email && <span className="text-danger">{errors.email}</span>}
                    </div>
                </div>

                <button type="submit" className="btn btn-primary">تحديث البيانات</button>

                {success && <p className="text-success mt-2">{success}</p>}

            </form>
        </div>
    );
};

export default MyInfo;
