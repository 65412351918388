import React, { useState, useEffect } from 'react';
import AdminLayout from "../layout/adminLayout";

const OurWork = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [description, setDescription] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [uploadSuccess, setUploadSuccess] = useState(false); // State for upload success message
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState('');

    // Function to handle file selection
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    // Function to handle file selection
    const handleDescription = (event) => {
        setDescription(event.target.value);
    };

    // Function to handle service selection
    const handleServiceChange = (event) => {
        setSelectedService(event.target.value);
    };

    // Function to fetch services
    const fetchServices = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/services`);
            const data = await response.json();
            setServices(data.result.services);
        } catch (error) {
            console.error('Error fetching services:', error);
        }
    };
    const handleFileUpload = async () => {
        if (!selectedFile) return; // Check if both selectedFile and selectedService are present

        try {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('description', description);
            formData.append('service_id', selectedService); // Append selectedService to the form data

            // Send file to backend API
            await fetch(`${process.env.REACT_APP_API_URL}api/ourWorks/`, {
                method: 'POST',
                body: formData,
            });

            // Fetch updated list of uploaded files
            fetchUploadedFiles();
            // Set upload success message
            setUploadSuccess(true);

            // Reset file input, description, and selectedService
            setSelectedFile(null);
            setDescription("");
            setSelectedService(""); // Reset selectedService to clear the selection

        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };


    // Function to fetch uploaded files
    const fetchUploadedFiles = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/ourWorks?page=${currentPage}`);
            const data = await response.json();
            setUploadedFiles(data.ourWorks);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error('Error fetching uploaded files:', error);
        }
    };

    // Fetch uploaded files on component mount
    useEffect(() => {
        fetchUploadedFiles();
        fetchServices();
    }, [currentPage]);


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    // Function to delete a video
    const handleDelete = async (ourWorkId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this video?");
        if (confirmDelete) {
            try {
                await fetch(`${process.env.REACT_APP_API_URL}api/ourWorks/${ourWorkId}`, {
                    method: 'DELETE'
                });
                // Fetch updated list of uploaded files after deletion
                fetchUploadedFiles();
            } catch (error) {
                console.error('Error deleting video:', error);
            }
        }
    };

    return (
        <div className="App">
            <AdminLayout>
                <div >
                    <h3>تحميل فيديو</h3>
                    <input type="file" onChange={handleFileChange} />
                    <h3>الوصف</h3>
                    <input type={'text'} value={description} onChange={handleDescription}/>

                    <h3>الخدمة</h3>
                    <select className={'form-select'} value={selectedService} onChange={handleServiceChange}>
                    {/*<option >اختر الخدمة</option>*/}
                    <option>أخرى</option>
                    {services.map(service => (
                        <option key={service.service_id} value={service.service_id}>{service.service_name}</option>
                    ))}
                </select>
                    <button className={'float-start mt-3'} onClick={handleFileUpload}>تحميل</button>

                    {uploadSuccess && <p className="text-success">تم تحميل الملف بنجاح.</p>}

                </div>

                <br/>
                <br/>

                <div className={'row'}>
                    <h2>الأعمال السابقة</h2>
                    <div className={'row'}>
                        {uploadedFiles.map((file, index) => {
                            const videoPath = `${process.env.REACT_APP_API_URL}${file.file.path.replace(/\\/g, '/')}`;
                            return (
                                <div className={'col-6 col-sm-3 m-5 text-center'} key={index}>
                                    <h3>{file.description}</h3>
                                    <video key={file.ourWork_id} controls width="400" height="350">
                                        <source src={videoPath} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <button className={'col-12 mx-3 mt-2'} onClick={() => handleDelete(file.ourWork_id)}>Delete</button>
                                </div>
                            );
                        })}
                    </div>

<div className={'d-flex justify-content-center'}>
    <ul className="pagination mt-3 cursor-pointer">
        {/* First Page Button */}
        <li>
            <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>&laquo;</button>
        </li>
        {/* Previous Page Button */}
        <li>
            <button onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                    disabled={currentPage === 1}>&lt;</button>
        </li>
        {/* Display a range of pages around the current page */}
        {Array.from({length: totalPages > 5 ? 5 : totalPages}).map((_, index) => {
            const page = index + 1;
            const pageToShow = currentPage <= 3 ? page : currentPage + index - 2;
            return pageToShow <= totalPages ? (
                <li key={index}>
                    <button
                        onClick={() => handlePageChange(pageToShow)}
                        className={currentPage === pageToShow ? "active" : ""}
                    >
                        {pageToShow}
                    </button>
                </li>
            ) : null;
        })}
        {/* Next Page Button */}
        <li>
            <button onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                    disabled={currentPage === totalPages}>&gt;</button>
        </li>
        {/* Last Page Button */}
        <li>
            <button onClick={() => handlePageChange(totalPages)}
                    disabled={currentPage === totalPages}>&raquo;</button>
        </li>
    </ul>

</div>


                </div>
            </AdminLayout>
        </div>
    );
};

export default OurWork;
