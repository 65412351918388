import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import { useAuth } from '../../../../AuthContext'; // Import your AuthContext
import CustomerLayout from "../../../layout/customerLayout";
import SimpleRegister from "../../../../SimpleRegister";
import ReservationSuccessModal from "../../../order/ReservationSuccessModal";
import Loading from "../../../../Loading";

const ServiceBeneficiariesList = ({beneficiaries, onSelectBeneficiary}) => {
    return (
        <div dir={'rtl'}>

            <div className="row">
                <div className="col-md-3">
                    <h2>تقديم الخدمة إلى</h2>
                </div>
                <div className="col-md-4">
                    <select className="form-select" style={{fontSize: "24px", border: "2px solid black"}}
                            onChange={(e) => onSelectBeneficiary(beneficiaries[e.target.selectedIndex - 1])}>
                        <option disabled selected>اختار المستفيد من الخدمة</option>
                        {beneficiaries.map((beneficiary, index) => (
                            <option key={beneficiary.service_beneficiaries_id} value={index}>
                                {beneficiary.beneficiaries_type}
                            </option>
                        ))}
                    </select>
                </div>
            </div>


        </div>
    );
};

const BeneficiaryInformationForm = ({ beneficiary, service, variant, userInputs, setUserInputs, handleShowReservationModal }) => {
    // Access the loggedIn state and login function from AuthContext
    const { loggedIn, isCompany, user } = useAuth();

    const [inputErrors, setInputErrors] = useState({});

    // State to control modal visibility
    const [showModal, setShowModal] = useState(false);

    // Function to toggle modal visibility
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    // Function to handle checkout button click
    const handleCheckout = () => {
        if (loggedIn) {
            // If logged in, show alert (You can replace this with your logic)
            reserveOrder();
        } else {
            // If not logged in, show modal
            toggleModal();
        }
    };

    // Function to handle form submission (reserveOrder)
    const reserveOrder = async () => {

        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`;

        // Check if any required fields are empty
        const required_information = userInputs;
        let flag = false;
        const errors = {};

        Object.keys(required_information).forEach(key => {
            if (required_information[key] === '') {
                flag = true;
                errors[key] = `الحقل ${key} فارغ.`;

            }
        });

        if (flag) {
            // If any required fields are empty, update inputErrors state with error messages
            setInputErrors(errors);
            return;
        }

        try {
            const orderData = {
                service_id: service.service_id,
                service_variant_id: variant.service_variant_id,
                service_beneficiaries_id: beneficiary.service_beneficiaries_id,
                services_information_required: userInputs,
                price: isCompany ? variant.price_for_companies : variant.price,
                order_date: formattedDate, // Assuming the current date/time is used
                user_id: loggedIn ? user.user_id : null, // Replace 'user_id_value' with the actual user ID
                payment_method: "not_paid_will_contact_using_whatsapp",
                payment_status: "PENDING",
                payment_details: {},
            };

            // Make the API call to create the order
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/orders`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderData),
            });

            if (!response.ok) {
                throw new Error('Failed to create order');
            }

            // Handle successful order creation (e.g., show success message, redirect, etc.)
            handleShowReservationModal();
        } catch (error) {
            console.error('Error creating order:', error);
            // Handle error (e.g., show error message)
        }
    };

    const handleInputChange = (e, infoName) => {
        setInputErrors({ ...inputErrors, [infoName]: '' });

        if (e.target.type === 'file') {
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    const img = new Image();
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        // Set the maximum dimensions for resizing
                        const maxWidth = 200;
                        const maxHeight = 200;

                        // Calculate new dimensions while maintaining aspect ratio
                        let newWidth = img.width;
                        let newHeight = img.height;
                        if (newWidth > maxWidth) {
                            newHeight *= maxWidth / newWidth;
                            newWidth = maxWidth;
                        }
                        if (newHeight > maxHeight) {
                            newWidth *= maxHeight / newHeight;
                            newHeight = maxHeight;
                        }

                        // Set canvas dimensions
                        canvas.width = newWidth;
                        canvas.height = newHeight;

                        // Draw image on canvas
                        ctx.drawImage(img, 0, 0, newWidth, newHeight);

                        // Get base64 representation of resized image
                        const base64String = canvas.toDataURL('image/jpeg');

                        // Set the value to the base64 string
                        setUserInputs({
                            ...userInputs,
                            [infoName]: base64String,
                        });
                    };
                    img.src = reader.result;
                };
                reader.readAsDataURL(file);
            }
        } else if (e.target.type === 'date') {
            // If the input type is date, format it to DD/MM/YYYY
            const date = new Date(e.target.value);
            const formattedDate = `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
            setUserInputs({
                ...userInputs,
                [infoName]: formattedDate,
            });
        } else {
            // For other input types, use the input value directly
            const value = e.target.value;
            setUserInputs({
                ...userInputs,
                [infoName]: value,
            });
        }
    };


    const resizeAndConvertToBase64 = (file, maxWidth, maxHeight) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // Calculate new dimensions while maintaining aspect ratio
                    let newWidth = img.width;
                    let newHeight = img.height;
                    if (newWidth > maxWidth) {
                        newHeight *= maxWidth / newWidth;
                        newWidth = maxWidth;
                    }
                    if (newHeight > maxHeight) {
                        newWidth *= maxHeight / newHeight;
                        newHeight = maxHeight;
                    }

                    // Set canvas dimensions
                    canvas.width = newWidth;
                    canvas.height = newHeight;

                    // Draw image on canvas
                    ctx.drawImage(img, 0, 0, newWidth, newHeight);

                    // Get base64 representation of resized image
                    const base64String = canvas.toDataURL('image/jpeg');

                    resolve(base64String);
                };
                img.onerror = reject;
            };
            reader.onerror = reject;
        });
    };

    const handleRegistrationSuccess = () => {
        setShowModal(false); // Close the modal upon successful registration
    };

    const handleRegister = () => {
        // If the registration is initiated from the checkout page
        setShowModal(true);
    };

    const onClose = () => {
        setShowModal(false);
    }


    return (
        <div className={'mt-5'}>
            <h2>حجز خدمة
                {' - '}
                {service.service_name}
                {' - '}
                {beneficiary.beneficiaries_type}</h2>

            <div className={'row'}>
                <div className={'col-12 col-md-6'}>
                    <form onSubmit={reserveOrder}>

                        {beneficiary.services_informations_required.map(info => (
                            <div key={info.service_information_required_id} className="mb-3">
                                <label>{info.information_required_name}</label>
                                <input
                                    type={info.information_required_type}
                                    onChange={(e) => handleInputChange(e, info.information_required_name)}
                                />
                                {/* Display error message if input field is empty */}
                                {inputErrors[info.information_required_name] && (
                                    <div className="text-danger">{inputErrors[info.information_required_name]}</div>
                                )}
                            </div>
                        ))}

                    </form>
                </div>

                <div className={'col-12 col-md-6'}>
                    <h2>تفاصيل الطلب</h2>
                    {beneficiary && (
                        <div>
                            <p><b>{service.service_name} {beneficiary.beneficiaries_type} ({variant.service_variant_name})</b></p>
                            {/* Add more order details here */}
                            <p><b>التكلفة : {isCompany ? variant.price_for_companies : variant.price} (للكمية الواحدة)</b></p>
                            {/* Add checkout button */}
                            <button className={'p-2'} onClick={handleCheckout}>الدفع وتأكيد الحجز</button>
                        </div>
                    )}
                </div>
            </div>

            {/* Modal for registration */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={toggleModal}>&times;</span>
                        <SimpleRegister onSuccess={handleRegistrationSuccess} onClose={onClose}/>
                    </div>
                </div>
            )}
        </div>

    );
};

const ServiceDetails = () => {
    const navigate = useNavigate();
    const { service_id, service_variant_id } = useParams();
    const [service, setService] = useState(null);
    const [variant, setVariant] = useState(null);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [userInputs, setUserInputs] = useState({});
    const [showReservationModal, setShowReservationModal] = useState(false);

    const handleCloseReservationModal = () => {
        setShowReservationModal(false);
        navigate('/');
    };
    const handleShowReservationModal = () => setShowReservationModal(true);

    useEffect(() => {
        const fetchServiceData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}api/services/${service_id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch service');
                }
                const data = await response.json();
                setService(data.result.service);
                const variant = data.result.service.services_variants.find(variant => variant.service_variant_id == service_variant_id);
                setVariant(variant);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchServiceData();
    }, [service_id]);

    const handleSelectBeneficiary = (beneficiary) => {
        setSelectedBeneficiary(beneficiary);
        const inputs = {};
        beneficiary.services_informations_required.forEach(info => {
            inputs[info.information_required_name] = '';
        });
        setUserInputs(inputs);
    };

    if (isLoading) {
        return (
            <div className={`transition-container ${isLoading ? '' : 'show'}`}>
                <div className="loading-container">
                    <Loading />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={`transition-container ${isLoading ? '' : 'show'}`}>
                <div className="loading-container">
                    <div>Error: {error}</div>
                </div>
            </div>
        );
    }

    return (
        <div className={`transition-container ${isLoading ? '' : 'show'}`}>
            <CustomerLayout height={70}>
                <div className={'mt-4 mx-5'} dir={'rtl'}>
                    <h1>{service && service.service_name}</h1>
                    <ServiceBeneficiariesList
                        beneficiaries={service && service.beneficiaries}
                        onSelectBeneficiary={handleSelectBeneficiary}
                    />
                    {selectedBeneficiary && (
                        <BeneficiaryInformationForm
                            beneficiary={selectedBeneficiary}
                            service={service}
                            variant={variant}
                            userInputs={userInputs}
                            setUserInputs={setUserInputs}
                            handleShowReservationModal={handleShowReservationModal}
                        />
                    )}
                    <ReservationSuccessModal showModal={showReservationModal} handleClose={handleCloseReservationModal} />
                </div>
            </CustomerLayout>
        </div>
    );
};


export default ServiceDetails;
