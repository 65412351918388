import React, {useState} from 'react';
import './ContactForm.css'; // Assuming you have a CSS file for styling
import {send, sendHover} from '../../icons';
import emailjs from '@emailjs/browser';
import CustomerLayout from "../layout/customerLayout";

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });
    const [successMessage, setSuccessMessage] = useState('');

    const [loading, setLoading] = useState(false);


    const handleChange = e => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/contactUs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                setLoading(false);
                setSuccessMessage('تم إرسال رسالتك بنجاح. سأقوم بالرد عليك في أقرب وقت ممكن.');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: ''
                });
            } else {
                throw new Error('Failed to submit form');
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
            alert('Something went wrong. Please try again.');
        }
    };

    return (
        <CustomerLayout height={70}>

        <div className="contact-form-container mt-5" dir={'rtl'}>


                <h2>ابقى على تواصل</h2>
                <h3>تحدث معنا .</h3>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">الإسم الكامل :</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">رقم الهاتف :</label>
                        <input
                            type="text"
                            id="name"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">عنوان بريدك الإلكتروني :</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">شاركنا أفكارك :</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>
                    <button type="submit"

                            onMouseOver={() => {
                                document
                                    .querySelector('.contact-btn')
                                    .setAttribute('src', sendHover);
                            }}
                            onMouseOut={() => {
                                document.querySelector('.contact-btn').setAttribute('src', send);
                            }}>
                        {loading ? 'إرسال الرسالة ...' : 'إرسال'}
                        <img
                            src={send}
                            alt="send"
                            className="contact-btn"
                            style={{width: '30px', height: '30px', margin: '0 5px 0 5px'}}
                        />


                    </button>

                    {successMessage && <p style={{fontSize: "16px"}} className={'success mt-3'}>{successMessage}</p>}

                </form>
        </div>
</CustomerLayout>

);
};

export default ContactForm;

