import React, { useState, useEffect } from 'react';
import AdminLayout from "../layout/adminLayout";

const Messages = () => {
    const [messages, setMessages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchMessages();
    }, [currentPage]);

    const fetchMessages = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/contactUs?page=${currentPage}`);
            if (response.ok) {
                const data = await response.json();
                setMessages(data.contactUs);
                setTotalPages(data.totalPages);
            } else {
                throw new Error('Failed to fetch messages');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    return (
        <div className="App">
            <AdminLayout>
                <h2>الرسائل</h2>
                <table>
                    <thead>
                    <tr>
                        <th>الأسم</th>
                        <th>رقم الهاتف</th>
                        <th>البريد الالكتروني</th>
                        <th>الرسالة</th>
                    </tr>
                    </thead>
                    <tbody>
                    {messages.map(message => (
                        <tr key={message.contactUs_id}>
                            <td>{message.name}</td>
                            <td>{message.phone}</td>
                            <td>{message.email}</td>
                            <td>{message.message}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>


                <div className={'d-flex justify-content-center'}>
                    <ul className="pagination mt-3 cursor-pointer">
                        {/* First Page Button */}
                        <li>
                            <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>&laquo;</button>
                        </li>
                        {/* Previous Page Button */}
                        <li>
                            <button onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                                    disabled={currentPage === 1}>&lt;</button>
                        </li>
                        {/* Display a range of pages around the current page */}
                        {Array.from({length: totalPages > 5 ? 5 : totalPages}).map((_, index) => {
                            const page = index + 1;
                            const pageToShow = currentPage <= 3 ? page : currentPage + index - 2;
                            return pageToShow <= totalPages ? (
                                <li key={index}>
                                    <button
                                        onClick={() => handlePageChange(pageToShow)}
                                        className={currentPage === pageToShow ? "active" : ""}
                                    >
                                        {pageToShow}
                                    </button>
                                </li>
                            ) : null;
                        })}
                        {/* Next Page Button */}
                        <li>
                            <button onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                                    disabled={currentPage === totalPages}>&gt;</button>
                        </li>
                        {/* Last Page Button */}
                        <li>
                            <button onClick={() => handlePageChange(totalPages)}
                                    disabled={currentPage === totalPages}>&raquo;</button>
                        </li>
                    </ul>

                </div>


            </AdminLayout>
        </div>
    );
};

export default Messages;
