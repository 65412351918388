// AuthContext.js
import React, {createContext, useState, useContext, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [loggedIn, setLoggedIn] = useState(localStorage.getItem('ALANEES_loggedIn'));
    const [user, setUser] = useState(null);
    const [isCompany, setIsCompany] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // const storedLoggedIn = localStorage.getItem('ALANEES_loggedIn');
        // if (storedLoggedIn) {
        //     setLoggedIn(true);
        // }

        const storedToken = localStorage.getItem('ALANEES_accessToken');

        if (storedToken) {
            try {
                const decodedToken = jwtDecode(storedToken);
                setUser(decodedToken.user); // Assuming the decoded token contains user information
                setLoggedIn(true);
            } catch (error) {
                // Handle error if the token is invalid or cannot be decoded
                console.error('Error decoding token:', error);
                setLoggedIn(false);
            }
        }

    }, []);

    const login = async (formData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            if (!response.ok) {
                throw new Error("البريد الإلكتروني أو كلمة المرور غير صحيحة");
            }
            const responseData = await response.json();
            // Store accessToken in localStorage with token name "ALANEES_accessToken"
            localStorage.setItem('ALANEES_accessToken', responseData.accessToken);
            localStorage.setItem('ALANEES_loggedIn', true);
            localStorage.setItem('ALANEES_display_name', responseData.user.display_name);
            setLoggedIn(true);
            setUser(responseData.user);

            if (responseData.user.role === "COMPANY") {
                setIsCompany(true);
            }




            return responseData;
        } catch (error) {
            console.error("Error occurred:", error);
            return false;
        }
    };

    const register = async (formData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            if (!response.ok) {
                const responseData = await response.json();
                let errors = {};
                errors = (responseData.errors.reduce((acc, error) => {
                    acc[error.path] = error.msg;
                    return acc;
                }, {}));

                return errors;


            } else {
                const responseData = await response.json();
                // Store accessToken in localStorage
                localStorage.setItem('ALANEES_accessToken', responseData.accessToken);
                localStorage.setItem('ALANEES_loggedIn', true);
                localStorage.setItem('ALANEES_display_name', responseData.user.display_name);

                setUser(responseData.user);
                setLoggedIn(true);
                return true;
            }

        } catch (error) {
            console.error("Error occurred:", error);
            return false;
        }
    };

    const logout = () => {
        // Perform your logout logic here
        setUser(null);
        setLoggedIn(false);
        localStorage.removeItem('ALANEES_loggedIn');
        localStorage.removeItem('ALANEES_display_name');
        localStorage.removeItem('ALANEES_accessToken');
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ loggedIn, login, register, logout, user,setUser, isCompany }}>
            {children}
        </AuthContext.Provider>
    );
};
