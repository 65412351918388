import React, {useState, useEffect} from 'react';
import AdminLayout from "../layout/adminLayout";
import {toast} from 'react-toastify';

const ServiceList = () => {
    // State variables
    const [services, setServices] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteServiceId, setDeleteServiceId] = useState(null);
    const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [editServiceData, setEditServiceData] = useState(null);
    // Effect to fetch service list
    useEffect(() => {
        fetchServiceList();
    }, []);

    // Function to fetch service list
    const fetchServiceList = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/services`);
            const data = await response.json();
            if (response.ok) {
                setServices(data.result.services);
            } else {
                console.error('Failed to fetch service list:', data.error);
            }
        } catch (error) {
            console.error('Error fetching service list:', error);
        }
    };

    // Function to edit service
    const editService = async (serviceId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/services/${serviceId}`);
            const data = await response.json();
            if (response.ok) {
                setEditServiceData(data.result.service);
                setShowEditModal(true);
            } else {
                console.error('Failed to fetch service:', data.error);
            }
        } catch (error) {
            console.error('Error fetching service:', error);
        }
    };

    // Function to confirm delete service
    const confirmDeleteService = (serviceId) => {
        setShowConfirmationModal(true);
        setDeleteServiceId(serviceId);
    };

    // Function to cancel delete service
    const cancelDeleteService = () => {
        setShowConfirmationModal(false);
        setDeleteServiceId(null);
    };

    // Function to delete confirmed service
    const deleteConfirmedService = async () => {
        // Reset success and error messages
        setDeleteSuccessMessage('');
        setDeleteErrorMessage('');

        if (deleteServiceId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}api/services/${deleteServiceId}`, {
                    method: 'DELETE',
                });
                const responseData = await response.json();
                if (response.ok) {
                    if (responseData.result.success) {
                        fetchServiceList();
                        setDeleteSuccessMessage("تم حذف الخدمة بنجاح");
                    } else {
                        setDeleteErrorMessage("لا تستطيع حذف هذه الخدمة لأرتباطها في العديد من الطلبات");
                    }
                } else {
                    setDeleteErrorMessage('حدثت مشكلة في حذف الخدمة');
                }
            } catch (error) {
                console.error('Error deleting service:', error);
                setDeleteErrorMessage('حدث خطأ في الحذف');
            } finally {
                setShowConfirmationModal(false);
                setDeleteServiceId(null);
            }
        }
    };

    // Function to update service
    const updateService = async (updatedServiceData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/services/${updatedServiceData.service_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedServiceData),
            });
            const responseData = await response.json();
            if (response.ok) {
                // Handle success
                fetchServiceList();
                setShowEditModal(false);
                toast.success('تم تعديل الخدمة بنجاح');


            } else {
                // Handle error
                toast.error('لم يتم تعديل الخدمة, خطأ ما حدث');
                console.error('Failed to update service:', responseData.error);
            }
        } catch (error) {
            toast.error('Failed to update service');
            console.error('Error updating service:', error);
        }
    };

    return (
        <div>
            <AdminLayout>
                <h2>قائمة الخدمات</h2>

                {/* Delete Success Message */}
                {deleteSuccessMessage && <h4 className="success success_msg">{deleteSuccessMessage}</h4>}
                {/* Delete Error Message */}
                {deleteErrorMessage && <h3 className="error err_msg">{deleteErrorMessage}</h3>}

                <table>
                    <thead>
                    <tr>
                        <th>رقم</th>
                        <th>اسم الخدمة</th>
                        <th>الوصف</th>
                        <th>التحكم</th>
                    </tr>
                    </thead>
                    <tbody>
                    {services.map((service, index) => (
                        <tr key={service.service_id}>
                            <td>{index + 1}</td>
                            <td>{service.service_name}</td>
                            <td>{service.description}</td>
                            <td>
                                <button className={'mx-2 px-3'} onClick={() => editService(service.service_id)}>تعديل
                                </button>
                                <button className={'mx-2 px-3'}
                                        onClick={() => confirmDeleteService(service.service_id)}>حذف ( إخفاء )
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <ul className="pagination mt-3 cursor-pointer">
                    {/* Pagination logic */}
                </ul>
            </AdminLayout>
            {/* Confirmation Modal */}
            {showConfirmationModal && (
                <div className="modal" dir={'rtl'}>
                    <div className="modal-content">
                        <span className="close" onClick={cancelDeleteService}>&times;</span>
                        <h3>حذف (إخفاء) الخدمة</h3>
                        {/* Confirm delete service message */}
                        <p>هل أنت متأكد أنك تريد حذف هذه الخدمة؟</p>
                        <button onClick={deleteConfirmedService}>نعم، حذف</button>
                    </div>
                </div>
            )}

            {/* Edit Modal */}
            {showEditModal && editServiceData && (
                <div className="modal" dir={'rtl'}>
                    <div className="modal-content"
                         style={{maxWidth: "100%", width: "95%", height: "500px", overflowY: "scroll"}}>
                        <span className="close" onClick={() => setShowEditModal(false)}>&times;</span>
                        <h3>تعديل الخدمة</h3>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            updateService(editServiceData);
                        }}>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <label htmlFor="service_name">اسم الخدمة:</label>
                                    <input type="text" id="service_name" name="service_name"
                                           value={editServiceData.service_name}
                                           onChange={(e) => setEditServiceData({
                                               ...editServiceData,
                                               service_name: e.target.value
                                           })} required/>
                                </div>
                                <div className={'col-12 col-md-6'}>
                                    <label htmlFor="description">الوصف: </label>
                                    <textarea id="description" name="description" value={editServiceData.description}
                                              onChange={(e) => setEditServiceData({
                                                  ...editServiceData,
                                                  description: e.target.value
                                              })} required/>
                                </div>
                            </div>


                            {
                                editServiceData.beneficiaries && editServiceData.beneficiaries.map((beneficiary, index) => (
                                    <div key={index} className="beneficiary-section mb-2 mt-2">
                                        <h5 className={'bold'}>اسم المستفيد : {beneficiary.beneficiaries_type}</h5>
                                        <div className="row mb-2">
                                            {beneficiary.services_informations_required.map((info, infoIndex) => (

                                                <div className="col-4">
                                                    <label>{info.information_required_name}:</label>
                                                    <select
                                                        name="information_required_type"
                                                        className={`information_required_type_${index} form-select`}
                                                        value={info.information_required_type}
                                                        onChange={(e) => {
                                                            const updatedBeneficiaries = [...editServiceData.beneficiaries];
                                                            updatedBeneficiaries[index].services_informations_required[infoIndex].information_required_type = e.target.value;
                                                            setEditServiceData({
                                                                ...editServiceData,
                                                                beneficiaries: updatedBeneficiaries
                                                            });
                                                        }}
                                                    >
                                                        <option value="text">نص</option>
                                                        <option value="date">تاريخ</option>
                                                        <option value="number">رقم</option>
                                                        <option value="file">ملف</option>
                                                    </select>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))
                            }


                            {/* Service Variants Fields */}
                            {editServiceData.services_variants && editServiceData.services_variants.map((variant, index) => (
                                <div key={index} className="row mb-2 mt-2">
                                    <div className="col-2">
                                        <label>البديل {index + 1} : نوع البديل</label>
                                        <input type="text"
                                               className={`service_variant_name_${index}`}
                                               name="service_variant_name" value={variant.service_variant_name}
                                               placeholder="ادخل اسم البديل هنا"
                                               onChange={(e) => {
                                                   const updatedVariants = [...editServiceData.services_variants];
                                                   updatedVariants[index].service_variant_name = e.target.value;
                                                   setEditServiceData({
                                                       ...editServiceData,
                                                       services_variants: updatedVariants
                                                   });
                                               }}/>
                                    </div>

                                    <div className="col-2">
                                        <label>السعر التكلفة:</label>
                                        <input type="number"
                                               className={`base_price_${index}`}
                                               name="base_price" value={variant.base_price}
                                               placeholder="ادخل السعر هنا"
                                               onChange={(e) => {
                                                   const updatedVariants = [...editServiceData.services_variants];
                                                   updatedVariants[index].base_price = parseFloat(e.target.value);
                                                   setEditServiceData({
                                                       ...editServiceData,
                                                       services_variants: updatedVariants
                                                   });
                                               }}/>
                                    </div>

                                    <div className="col-2">
                                        <label>السعر:</label>
                                        <input type="number"
                                               className={`price_${index}`}
                                               name="price" value={variant.price}
                                               placeholder="ادخل السعر هنا"
                                               onChange={(e) => {
                                                   const updatedVariants = [...editServiceData.services_variants];
                                                   updatedVariants[index].price = parseFloat(e.target.value);
                                                   setEditServiceData({
                                                       ...editServiceData,
                                                       services_variants: updatedVariants
                                                   });
                                               }}/>
                                    </div>
                                    <div className="col-2">
                                        <label>سعر الشركات:</label>
                                        <input type="number"
                                               className={`price_for_companies_${index}`}
                                               name="price_for_companies" value={variant.price_for_companies}
                                               placeholder="ادخل سعر الشركات هنا"
                                               onChange={(e) => {
                                                   const updatedVariants = [...editServiceData.services_variants];
                                                   updatedVariants[index].price_for_companies = parseFloat(e.target.value);
                                                   setEditServiceData({
                                                       ...editServiceData,
                                                       services_variants: updatedVariants
                                                   });
                                               }}/>
                                    </div>

                                </div>
                            ))}

                            <div className="row">
                                <button type="submit" className="btn btn-primary">تحديث الخدمة</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServiceList;
