import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Cards from '../../../home/component/card/card';
import CustomerLayout from '../../../layout/customerLayout';
import { useAuth } from '../../../../AuthContext';
import Loading from '../../../../Loading'; // Import the Loading component

const ServiceVariants = () => {
    const { service_id } = useParams();
    const [variants, setVariants] = useState([]);
    const { isCompany } = useAuth();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Fetch variants data based on service_id
        const fetchVariants = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}api/services/${service_id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch variants');
                }
                const data = await response.json();
                setVariants(data.result.service.services_variants);
            } catch (error) {
                console.error('Error fetching variants:', error);
            } finally {
                setIsLoading(false); // Set loading state to false regardless of success or failure
            }
        };

        fetchVariants();

        // Cleanup function
        return () => {
            // Cleanup code if needed
        };
    }, [service_id]);

    return (
        <div className={`transition-container ${isLoading ? '' : 'show'}`}>
            <div className="loading-container">
                {isLoading && <Loading />}
            </div>
            {!isLoading && (
                <CustomerLayout height={70}>
                    <div className={'mt-4 mx-5'}>
                        <div className={'row text-center'}>
                            {variants.map((variant) => (
                                <div key={variant.service_variant_id} className="col-12 col-sm-6 col-md-4">
                                    <Cards
                                        service_name={variant.service_variant_name}
                                        description={
                                            isCompany ? variant.price_for_companies : variant.price + 'شيكل'
                                        }
                                        imgPath={variant.file?.path ?? ''}
                                        navigateAction={`/services/${service_id}/variants/${variant.service_variant_id}/order`}
                                        actionText={'احجز الآن'}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </CustomerLayout>
            )}
        </div>
    );
};

export default ServiceVariants;
