import React, { useState, useEffect } from 'react';
import AdminLayout from "../layout/adminLayout";

const CommonQuestion = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [description, setDescription] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [isAnswerAudio, setIsAnswerAudio] = useState(false);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };



    const handleQuestionChange = (event) => {
        setQuestion(event.target.value);
    };

    const handleAnswerChange = (event) => {
        setAnswer(event.target.value);
    };

    const handleAnswerTypeChange = (event) => {
        setIsAnswerAudio(event.target.value === 'audio');
    };



    const handleFileUpload = async () => {
        if (!question || (!selectedFile && !answer)) return;

        try {
            const formData = new FormData();
            formData.append('question', question);
            formData.append('type', isAnswerAudio);

            if (isAnswerAudio && selectedFile) {
                formData.append('file', selectedFile);
            } else {
                formData.append('answer', answer);
            }

            await fetch(`${process.env.REACT_APP_API_URL}api/commonQuestions/`, {
                method: 'POST',
                body: formData,
            });

            fetchUploadedFiles();
            setUploadSuccess(true);
            setSelectedFile(null);
            setDescription("");
            setQuestion('');
            setAnswer('');
            setIsAnswerAudio(false);

        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const fetchUploadedFiles = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/commonQuestions?page=${currentPage}`);
            const data = await response.json();
            setUploadedFiles(data.common_questions);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error('Error fetching uploaded files:', error);
        }
    };

    useEffect(() => {
        fetchUploadedFiles();
    }, [currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDelete = async (common_question_id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");
        if (confirmDelete) {
            try {
                await fetch(`${process.env.REACT_APP_API_URL}api/commonQuestions/${common_question_id}`, {
                    method: 'DELETE'
                });
                fetchUploadedFiles();
            } catch (error) {
                console.error('Error deleting item:', error);
            }
        }
    };

    return (
        <div className="App">
            <AdminLayout>
                <div>
                    <h3>تحميل الاسئلة الشائعة</h3>
                    <h3>السؤال</h3>
                    <input type="text" value={question} onChange={handleQuestionChange} />

                    <h3>نوع الإجابة</h3>
                    <select onChange={handleAnswerTypeChange} value={isAnswerAudio ? 'audio' : 'text'}>
                        <option value="text">نص</option>
                        <option value="audio">صوت</option>
                    </select>

                    {isAnswerAudio ? (
                        <div>
                            <h3>تحميل التسجيل الصوتي للإجابة</h3>
                            <input type="file"  onChange={handleFileChange} />
                        </div>
                    ) : (
                        <div>
                            <h3>إجابة السؤال</h3>
                            <textarea value={answer} onChange={handleAnswerChange}></textarea>
                        </div>
                    )}



                    <button className="float-start mt-3" onClick={handleFileUpload}>رفع الإجابة</button>

                    {uploadSuccess && <p className="text-success">تم رفع الإجابة بنجاح</p>}
                </div>

                <br />
                <br />

                <div className="row">
                    <h2>الأسئلة والإجابات السابقة</h2>
                    <div className="row">
                        {uploadedFiles.map((file, index) => {
                            const filePath = `${process.env.REACT_APP_API_URL}${file.file?.path.replace(/\\/g, '/')}`;
                            return (
                                <div className="col-6 col-sm-3 m-5 text-center" key={index}>
                                    <h4>{file.question}</h4>
                                    {file.answer ? (
                                        <p>{file.answer}</p>
                                    ) : (
                                        <audio controls>
                                            <source src={filePath} type="audio/mp3" />
                                            Your browser does not support the audio element.
                                        </audio>
                                    )}
                                    <button className="col-12 mx-3 mt-2" onClick={() => handleDelete(file.common_question_id)}>Delete</button>
                                </div>
                            );
                        })}
                    </div>

                    <div className="d-flex justify-content-center">
                        <ul className="pagination mt-3 cursor-pointer">
                            <li>
                                <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>&laquo;</button>
                            </li>
                            <li>
                                <button onClick={() => handlePageChange(Math.max(currentPage - 1, 1))} disabled={currentPage === 1}>&lt;</button>
                            </li>
                            {Array.from({ length: totalPages > 5 ? 5 : totalPages }).map((_, index) => {
                                const page = index + 1;
                                const pageToShow = currentPage <= 3 ? page : currentPage + index - 2;
                                return pageToShow <= totalPages ? (
                                    <li key={index}>
                                        <button
                                            onClick={() => handlePageChange(pageToShow)}
                                            className={currentPage === pageToShow ? "active" : ""}
                                        >
                                            {pageToShow}
                                        </button>
                                    </li>
                                ) : null;
                            })}
                            <li>
                                <button onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))} disabled={currentPage === totalPages}>&gt;</button>
                            </li>
                            <li>
                                <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>&raquo;</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </AdminLayout>
        </div>
    );
};

export default CommonQuestion;
