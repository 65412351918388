// Login.js
import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';

const Login = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [error, setError] = useState("");

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const responseData = await login(formData);
        if (responseData && (responseData.user.role === "USER" || responseData.user.role === "COMPANY")) {
            navigate('/');
        } else if(responseData && responseData.user.role === "ADMIN") {
            navigate('/admin/dashboard');
        } else {
            setError("البريد الإلكتروني أو كلمة المرور غير صحيحة");
        }
    };
    useEffect(() => {
        const url = window.location.href; // Gets the current URL
        console.log("Current URL:", url);

        if (url.includes('localhost')) {
            console.log("The app is running locally.");
        } else {
            console.log("The app is running on a server.");
        }
    }, []);

    return (
        <section className="h-full bg-light">
            <div className="container h-full p-5">
                <div className="row h-full align-items-center justify-content-center text-dark">
                    <div className="col-lg-6" id={'registerSection'}>

                        <span className="d-inline-flex align-items-center mx-2 " dir="rtl" id={'homePageIcon'}>
                            <Link className="d-inline-block" to="/">
                                <i aria-hidden="true" className="fa fa-home"
                                   style={{fontSize: "27px"}}
                                ></i></Link>
                        </span>


                        <div className="block rounded bg-white shadow-lg">
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="mx-4 my-4">
                                        <div className="text-center">
                                            <img
                                                className="mx-auto img-fluid"
                                                style={{width: "250px"}}
                                                src="/logo.png"
                                                alt="logo"
                                            />
                                        </div>

                                        <div className="d-flex justify-content-center" dir={'rtl'}>
                                            <div>
                                                <p className="mb-4">صفحة تسجيل دخول</p>

                                                <form onSubmit={handleSubmit}>
                                                    <div className={'row'}>
                                                        <input type={'text'} className={'form-control'}
                                                               placeholder="ادخل البريد الالكتروني"
                                                               name="email" onChange={handleChange}/>
                                                    </div>

                                                    <div className={'row'}>
                                                        <input type={'password'} className={'form-control mt-2'}
                                                               placeholder="ادخل الرقم السري"
                                                               name="password" onChange={handleChange}/>
                                                    </div>

                                                    {/* Error message */}
                                                    {error && <div className="text-danger mt-2">{error}</div>}

                                                    <div className={'row'}>
                                                        <button
                                                            className="mb-3 btn mt-3 btn-block shining"
                                                            type="submit"
                                                            style={{
                                                                background:
                                                                    "linear-gradient(to right, rgb(245, 227, 102), rgb(255, 209, 102), rgb(255, 180, 102), rgb(255, 153, 102))\n",
                                                            }}
                                                        >
                                                            تسجيل دخول
                                                        </button>
                                                    </div>
                                                </form>

                                                <div className="mb-4 text-center">
                                                    {/* Forgot password link */}
                                                    <Link to="/forgetPassword" className="text-muted">هل نسيت الرقم
                                                        السري ؟</Link>
                                                </div>

                                                {/* Register button */}
                                                <div className="d-flex justify-content-between mb-4">
                                                    <p className="mb-0 mr-2">ليس لديك حساب ؟</p>
                                                    <Link
                                                        to="/register"
                                                        className="btn text-black btn-outline-warning btn shining"
                                                    >
                                                        تسجيل حساب جديد
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
