import React from 'react';
import CustomerLayout from "../layout/customerLayout";
import './commonQuestions.css'; // Import your CSS file for styling

const commonQuestions = () => {
    return (
        <div className="about-us-page">
            <CustomerLayout height={70}>
                <div className="about-us-content" dir={'rtl'}>
                    <h2>من نحن</h2>
                    <p>
                        الانيس مصلحة تنفذ خدمات مباشرة لأفراد وجمعيات بدون حاجة لوسيط في بعض الدول الإفريقية والآسيوية.
                    </p>
                    <h2>خدماتنا تقدم لعرب الداخل و تشمل:</h2>
                    <ul>
                        <li>خدمات الذبح: عقائق، أضاحي، كفارات يمين ونذور، عن روح، ومساهماتكم.</li>
                        <li>خدمات حفر الآبار.</li>
                        <li>تنفيذ عمرات وحجات البدل (العمرة أو الحجة بالإنابة) وذلك عن المريض/العاجز/المتوفي.</li>
                        <li>توثيقات بالفيديو / تنفيذ سريع.</li>
                    </ul>
                    <p>
                        الذبائح تناسب الشروط الشرعية في بلد الذبح. كما أنه بالإمكان تحقيق أي فكرة تطرحونها علينا بعد فحص إمكانية تنفيذها على أرض الواقع.
                    </p>
                    <h2>عنواننا</h2>
                    <p>مدينة حيفا</p>
                    <h2>للتواصل</h2>
                    <p>0549507745</p>
                </div>
            </CustomerLayout>
        </div>
    );
};

export default commonQuestions;
