import React, { useEffect, useState } from 'react';
import Cards from '../../../home/component/card/card';
import CustomerLayout from '../../../layout/customerLayout';
import Loading from '../../../../Loading';

const Services = () => {
    const [services, setServices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}api/services`);
                if (response.ok) {
                    const data = await response.json();
                    setServices(data.result.services ?? []);
                } else {
                    console.error('Failed to fetch service');
                }
            } catch (error) {
                console.error('Error fetching service:', error);
            } finally {
                setIsLoading(false); // Set loading state to false regardless of success or failure
            }
        };
        fetchServices();
    }, []);

    return (

        <div >
            <div className="loading-container">
                {isLoading && <Loading />}
            </div>
            {!isLoading && (
                <CustomerLayout height={90} className={`transition-container ${isLoading ? '' : 'show'}`}>
                    <div className={'mt-4 mx-5'}>
                        <div className={'row text-center services'}>
                            {services.map((service) => (
                                <div key={service.service_id} className="col-12 col-sm-6 col-md-4">
                                    <Cards
                                        service_name={service.service_name}
                                        description={service.description}
                                        imgPath={service.file.path}
                                        navigateAction={`/services/${service.service_id}/variants`}
                                        actionText={'احجز الآن'}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </CustomerLayout>
            )}
        </div>
    );
};

export default Services;
