import React from 'react';
import {
    MDBFooter,
    MDBContainer,
} from 'mdb-react-ui-kit';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook, faInstagram, faSignalMessenger } from '@fortawesome/free-brands-svg-icons';


export default function Footer() {

    const currentYear = new Date().getFullYear();


    return (
        <MDBFooter className='text-center mt-5' color='white' bgColor='dark'>
            <MDBContainer className='p-4'>
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <a target={'_blank'} href={'https://wa.me/972549507745'}>
                            <FontAwesomeIcon className={'text'} icon={faWhatsapp} size="1x" />
                        </a>
                    </div>
                    <div className="col-auto">
                        <a href={'https://facebook.com/alanees48'} target={'_blank'}>
                            <FontAwesomeIcon className={'text'} icon={faFacebook} size="1x" />
                        </a>
                    </div>
                    <div className="col-auto">
                        <a href={'https://www.instagram.com/alanees1948/'} target={'_blank'}>
                            <FontAwesomeIcon className={'text'} icon={faInstagram} size="1x" />
                        </a>
                    </div>
                </div>

                <section className='container-fluid row mb-4 mt-3 text-end'>

                    <div className={'col-12 col-md-6 text-center'}>
                                <h5 className='text-uppercase'>للاتصال والتواصل</h5>

                                <ul className='list-unstyled mb-0' >
                                    <li>
                                        <a target={'_blank'} href={'https://wa.me/972549507745'} className='text-white'>
                                            <FontAwesomeIcon icon={faWhatsapp} className='me-2' />
                                            WhatsApp
                                        </a>
                                    </li>
                                    <li>
                                        <a href='mailto:al.anees1948@gmail.com' className='text-white'>
                                            <FontAwesomeIcon icon={faSignalMessenger} className='me-2' />
                                            al.anees1948@gmail.com
                                        </a>
                                    </li>

                                </ul>
                    </div>

                    <div className={'col-12 col-md-6'}>
                        <p dir={'rtl'}>
                            الانيس مصلحة تنفذ خدمات مباشرة لأفراد وجمعيات بدون حاجة لوسيط في بعض الدول الإفريقية والآسيوية.
                        </p>




                        <p dir={'rtl'}>خدماتنا تشمل:</p>

                        <ul dir={'rtl'}>
                            <li>خدمات الذبح: عقائق، أضاحي، كفارات يمين ونذور، عن روح، ومساهماتكم.</li>
                            <li>خدمات حفر الآبار.</li>
                            <li>تنفيذ عمرات وحجات البدل (العمرة أو الحجة بالإنابة) وذلك عن المريض/العاجز/المتوفي.</li>
                            <li>توثيقات بالفيديو / تنفيذ سريع.</li>
                        </ul>

                        <p dir={'rtl'}>
                            الذبائح تناسب الشروط الشرعية في بلد الذبح. كما أنه بالإمكان تحقيق أي فكرة تطرحونها علينا بعد فحص إمكانية تنفيذها على أرض الواقع.
                        </p>
                    </div>




                </section>

                {/*<section className=''>*/}
                {/*    <MDBRow>*/}
                {/*        <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>*/}
                {/*            <h5 className='text-uppercase'>Links</h5>*/}

                {/*            <ul className='list-unstyled mb-0'>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 1*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 2*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 3*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 4*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </MDBCol>*/}

                {/*        <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>*/}
                {/*            <h5 className='text-uppercase'>Links</h5>*/}

                {/*            <ul className='list-unstyled mb-0'>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 1*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 2*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 3*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 4*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </MDBCol>*/}

                {/*        <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>*/}
                {/*            <h5 className='text-uppercase'>Links</h5>*/}

                {/*            <ul className='list-unstyled mb-0'>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 1*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 2*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 3*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 4*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </MDBCol>*/}

                {/*        <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>*/}
                {/*            <h5 className='text-uppercase'>Links</h5>*/}

                {/*            <ul className='list-unstyled mb-0'>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 1*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 2*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 3*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    <a href='#!' className='text-white'>*/}
                {/*                        Link 4*/}
                {/*                    </a>*/}
                {/*                </li>*/}
                {/*            </ul>*/}
                {/*        </MDBCol>*/}
                {/*    </MDBRow>*/}
                {/*</section>*/}
            </MDBContainer>

            <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                <span className='text-white mx-2' href='https://mdbootstrap.com/'>
                    الأنيس
                </span>

                Copyright © {currentYear}

            </div>
        </MDBFooter>
    );
}
