import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BsCheckCircleFill } from 'react-icons/bs'; // Importing the check circle icon

const ReservationSuccessModal = ({ showModal, handleClose }) => {
    return (
        <Modal show={showModal} onHide={handleClose} dialogClassName="modal-xl" className="modal-center reserved_modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <BsCheckCircleFill size="2em" className="text-success me-2" /> {/* Icon added here */}
                    تم حجز الخدمة بنجاح!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{fontSize:"20px"}}>سوف نتواصل معك عبر الواتساب في أقرب وقت ممكن لتأكيد الحجز وترتيب التفاصيل.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    إغلاق
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReservationSuccessModal;
