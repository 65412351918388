// Loading.js
import React from 'react';

const Loading = () => {
    return (
        <div className="loading">
            <div className="logo-container">
                <img src="/logo.png" alt="Logo" className="logo" />
            </div>
            <div className="loading-indicator">
                <img src="/loading.gif" alt="Loading..." className="loading-gif" />
            </div>
        </div>
    );
};

export default Loading;
